import { useState } from "react";

import { useArmory, useArmoryDispatch } from "../contexts/ArmoryContext";
import { useArmoryApi } from "../hooks/useArmoryApi";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { Student, InventoryObject } from "../apis/armoryApi";
import StudentSearchAutoComplete from "./StudentSearchAutoComplete";

import 'primeicons/primeicons.css';
import { User } from '../apis/armoryApi';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

function CheckOutPanel({ isDisabled = false, assetSerial = "", onStudentChange = () => { }, onSuccess = () => { } }: { isDisabled?: boolean, assetSerial?: string, onStudentChange?: (student: Student | null) => void, onSuccess?: () => void }) {
    const { isCursorLoading } = useArmory();
    const dispatch = useArmoryDispatch();
    const { checkOut, getUnlockCode, userHasAssetType } = useArmoryApi();

  //const [user, setUser] = useState<User>();
    const [student, setStudent] = useState<Student | null>(null);
    const [serial, setSerial] = useState(assetSerial);

    const handleStudentChange = (student: Student | null) => {
        setStudent(student);
        onStudentChange(student);
    }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerial(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});   

      const response = await userHasAssetType(student?.id ?? 0, serial);

      if (response.statusCode === 200 && Array.isArray(response.data) && response.data.length > 0) {
        confirmCheckout(response.data);
      }
      else {
        checkOutAsset();
      }

      setTimeout(() => {
          dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});   
      }, 0);
  };

    const checkOutAsset = async () => {
        const studentFullName = student?.firstName + " " + student?.lastName;
        const response = await checkOut(student?.id ?? 0, studentFullName, serial);

        if (response.statusCode === 200) {
            const successMsg: string = `Asset: ${serial} - Check out to ${student?.firstName} ${student?.lastName} succeeded.`;
            dispatch({type: "SHOW_TOAST", payload: {severity: "success", summary: "Success", detail: successMsg, life: 3000}});
            dispatch({ type: "ADD_LOG_ENTRY", payload: {message: successMsg}});
            onSuccess();

            const unlockResponse = await getUnlockCode(serial);
            if (unlockResponse.data) {
                const unlockCode: string = unlockResponse.data;
                const unlockMsg: string = `Unlock code for ${serial}: ${unlockCode}`;
                dispatch({type: "SHOW_TOAST", payload: {severity: "info", summary: "Unlock Code", detail: unlockMsg, life: 3000}});
                dispatch({type: "ADD_LOG_ENTRY", payload: {message: unlockMsg}});
            } else {
                const errorMsg: string = `Asset: ${serial} - Could not retrieve Unlock Code. ${unlockResponse.error}`;
                dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
                dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
            }
        }
        else {
            const errorMsg: string = `Asset: ${serial} - Check out to ${student?.firstName} ${student?.lastName} failed. ${response.error}`;
            dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
            dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
        }

        if (!isDisabled) {
            setSerial("");
        }

        setStudent(null);
    };

  const confirmCheckout = (assets: InventoryObject[]) => {
      //extract serial numbers from assets and store in a single comma separated string
      let messageString = `This student already has ${assets.length <= 1 ? "an asset" : "assets"} of this kind checked out (${assets.map(asset => asset.serial).join(", ")}). Are you sure you want to check out this asset (${serial}) to this student?`;

      confirmDialog({
          message: messageString,
          header: "Confirm Check-Out",
          icon: "pi pi-exclamation-triangle",
          accept: checkOutAsset,
          acceptLabel: "Check-Out Asset",
          rejectLabel: "Cancel"
      })
  }

  return (
    <div>
      <div className="mb-3">
        <StudentSearchAutoComplete
          label="Check Out To"
          student={student}
          setStudent={handleStudentChange}
        />
      </div>
      <div className="">
                <label htmlFor='checkOutAssetInputText'>Asset Serial Number</label>
              <div className="p-inputgroup">
                    <ConfirmDialog />
                    <InputText
                        id='checkOutAssetInputText'
                        value={serial}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        disabled={isDisabled}
                    />
                    <Button
                      icon="pi pi-check"
                      className="p-button-success"
                      onClick={handleSubmit}
                      disabled={isCursorLoading}
                      title="Check Out Asset"
                    />
                </div>
            </div>
        </div>
  );
}

export default CheckOutPanel;
