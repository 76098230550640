import { useState } from "react";

import { Site } from "../apis/armoryApi";
import { useArmoryApi } from "../hooks/useArmoryApi";
import { useArmory, useArmoryDispatch } from "../contexts/ArmoryContext";
import SiteDropdown from "./SiteDropdown";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

const SendAssetsPanel: React.FC = () => {
  const { isCursorLoading, site } = useArmory();
  const dispatch = useArmoryDispatch();
  const { sendAssets, validateSerialNumbers } = useArmoryApi();
  const [destinationSite, setDestinationSite] = useState<Site | null>(null);
  const [serials, setSerials] = useState<string>("");
  const [isFormEnabled, setIsFormEnabled] = useState<boolean>(false);
  const [selectedSiteName, setSelectedSiteName] = useState<string>("");

  const handleSiteChange = (site: Site | null) => {
    setDestinationSite(site);
    setIsFormEnabled(site !== null);
    if (site) {
      setSelectedSiteName(site.name);
    }
  };

  const handleSerialChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSerials(e.target.value);
  };

  const handleSubmit = async () => {
    dispatch({type: "SET_VALUE", payload:{ key: "isCursorLoading", value: true } });

    //convert serials to array
    const serialArr = serials.split("\n");

    //validate serials
    const serialResponse = await validateSerialNumbers(serialArr);
    if (serialResponse.statusCode === 200) {
      //serials valid, assign to site
      const response = await sendAssets(serialArr, site?.id ?? 0, destinationSite?.id ?? 0);

      if (response.statusCode === 200) {
        const successMsg: string = `Send assets succeeded.`;
        dispatch({type: "SHOW_TOAST", payload: {severity: "success", summary: "Success", detail: successMsg, life: 3000}});
        dispatch({type: "ADD_LOG_ENTRY", payload: {message: `${successMsg}\r\nSerials: ${serialArr.join("\r\n")}`}});
        setSerials("");
        setSelectedSiteName("");
      } else {
        const errorMsg: string = `Send assets failed. ${response.error}`;
        dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
        dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
      }
    } else {
      const errorMsg: string = `One or more serial numbers failed to validate. ${serialResponse.error}`;
      dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
      dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
    }

    setTimeout(() => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);
  };

  return (
    <div className="p-fluid">
      <label htmlFor="siteDropdown">To Site</label>
      <SiteDropdown
        handleChange={handleSiteChange}
        selectedSiteName={selectedSiteName}
        disabled={isCursorLoading}
      />
      <InputTextarea
        value={serials}
        onChange={handleSerialChange}
        disabled={!isFormEnabled || isCursorLoading}
        placeholder="Enter or scan serials"
        autoResize={true}
        className="p-mb-3 p-inputtext-sm"
      />
      <Button
        label="Submit"
        onClick={handleSubmit}
        disabled={!isFormEnabled || isCursorLoading}
        className="p-button-rounded p-button-success p-mt-2"
      />
    </div>
  );
};

export default SendAssetsPanel;
