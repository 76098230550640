import { InventoryObject } from "../apis/armoryApi";

interface AssetDataListElementProps {
  label: string;
  value: any;
}

const AssetDataListElement: React.FC<AssetDataListElementProps> = ({
  label,
  value,
}) => {
  return (
    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
      <div className="text-500 w-6 md:w-3 font-medium">{label}</div>
      <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
        {value}
      </div>
    </li>
  );
};

interface AssetDetailsPanelProps {
  inventoryObject: InventoryObject;
}

const AssetDetailsPanel: React.FC<AssetDetailsPanelProps> = ({ inventoryObject }) => {
  return (
    <div>
      <ul className="list-none p-0 m-0">
        <AssetDataListElement label="ID" value={inventoryObject.id} />
        <AssetDataListElement label="Serial" value={inventoryObject.serial} />
        {inventoryObject.transitSite ? (
          <AssetDataListElement
            label="Site"
            value={`In Transit From ${inventoryObject.transitSite?.name} to ${inventoryObject.site?.name}`}
          />
        ) : (
          <AssetDataListElement label="Site" value={inventoryObject.site?.name} />
        )}
        {/* <AssetDataListElement
          label="Repair Status ID"
          value={inventoryObject.repairStatusId}
        />
        <AssetDataListElement
          label="Retirement Status ID"
          value={inventoryObject.retirementStatusId}
        /> */}
        <AssetDataListElement
          label="Asset Type"
          value={`${inventoryObject.assetSku?.type} - ${inventoryObject.assetSku?.make} ${inventoryObject.assetSku?.model}`}
        />
        <AssetDataListElement
          label="School Year"
          value={inventoryObject.siteSchoolYear}
        />
        <AssetDataListElement
          label="Lessee"
          value={inventoryObject.lessee?.name}
        />
		<AssetDataListElement
		  label="Status"
		  value={inventoryObject.status}
		/>
      </ul>
    </div>
  );
};

export default AssetDetailsPanel;
