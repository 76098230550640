import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';

import App from './client/App';
import reportWebVitals from './client/reportWebVitals';

import { ArmoryProvider } from "./client/contexts/ArmoryContext";
import { AuthProvider } from './client/contexts/AuthContext';
import {  HashRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ArmoryProvider>
        <HashRouter>
          <PrimeReactProvider>
            <App />
          </PrimeReactProvider>
        </HashRouter>
      </ArmoryProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
