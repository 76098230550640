import { useAuth } from "../contexts/AuthContext";
import {
  ApiResponse,
  InventoryObject,
  InventoryAbsoluteData,
  InventorySearchModel,
  Site,
  User,
  Staff,
  SiteMetrics,
  searchStaff,
  getAllStaff,
  getInventoryObject,
  getInventoryObjectsBySiteId,
  checkIn,
  checkOut,
  getSites,
  getAllUsers,
  getUserById,
  getUserByEmail,
  searchUsers,
  searchAssets,
  UserIdNameSearchResult,
  InventoryObjectTableEntry,
  getStaffById,
  StaffIdNameSearchResult,
  StudentIdNameSearchResult,
  searchStudents,
  InventoryOperation,
  getAssetHistory,
  getStatus,
  getStudentImage,
  getSiteMetrics,
  setStatusRepair,
  setStatusRetirement,
  setStatusMissing,
  getAbsoluteData,
  getUnlockCode,
  reportDamaged,
  UserPermissions,
  getUserPermissions,
  validateSerialNumbers,
  bulkAssignSite,
  bulkSetMissing,
  bulkSetRepair,
  bulkSetRetirement,
  bulkSetSiteUseOnly,
  getLaptopReport,
  getHotspotReport,
  reportMissing,
  sendAssets,
  receiveAssets,
  userHasAssetType
} from "../apis/armoryApi";
import { access } from "fs";

export function useArmoryApi() {
  const { accessToken } = useAuth();

  const getInventoryObjectFunc = async (
    serial: string
  ): Promise<ApiResponse<InventoryObject>> => {
    return getInventoryObject(accessToken ?? "", serial);
  };

  const getInventoryObjectsBySiteIdFunc = async (
    siteId: number,
    showUnavailable: boolean
  ): Promise<ApiResponse<InventoryObjectTableEntry[]>> => {
    return getInventoryObjectsBySiteId(
      accessToken ?? "",
      siteId,
      showUnavailable
    );
  };

  const checkInFunc = async (
    serial: string,
    siteId: number
  ): Promise<ApiResponse<null>> => {
    return checkIn(accessToken ?? "", serial, siteId);
  };

  const checkOutFunc = async (
    lesseeId: number,
    lesseeName: string,
    serial: string
  ): Promise<ApiResponse<null>> => {
    return checkOut(accessToken ?? "", lesseeId, lesseeName, serial);
  };

  const getSitesFunc = async (): Promise<ApiResponse<Site[]>> => {
    return getSites(accessToken ?? "");
  };

  const getAllUsersFunc = async (): Promise<ApiResponse<User[]>> => {
    return getAllUsers(accessToken ?? "");
  };

  const getUserByIdFunc = async (
    userId: number
  ): Promise<ApiResponse<User>> => {
    return getUserById(accessToken ?? "", userId);
  };

  const getUserByEmailFunc = async (
    email: string
  ): Promise<ApiResponse<User>> => {
    return getUserByEmail(accessToken ?? "", email);
  };

  const searchUsersFunc = async (
    filter: string
  ): Promise<ApiResponse<UserIdNameSearchResult[]>> => {
    return searchUsers(accessToken ?? "", filter);
  };

  const getAllStaffFunc = async (): Promise<ApiResponse<Staff[]>> => {
    return getAllStaff(accessToken ?? "");
  };

  const getStaffByIdFunc = async (
    userId: number
  ): Promise<ApiResponse<Staff>> => {
    return getStaffById(accessToken ?? "", userId);
  };

  const searchStaffFunc = async (
    filter: string
  ): Promise<ApiResponse<StaffIdNameSearchResult[]>> => {
    return searchStaff(accessToken ?? "", filter);
  };

  const searchStudentsFunc = async (
    filter: string
  ): Promise<ApiResponse<StudentIdNameSearchResult[]>> => {
    return searchStudents(accessToken ?? "", filter);
  };

  const searchAssetsFunc = async (
    searchModel: InventorySearchModel
  ): Promise<ApiResponse<InventoryObjectTableEntry[]>> => {
    return searchAssets(accessToken ?? "", searchModel);
  };

  const getAssetHistoryFunc = async (
    assetId: number
  ): Promise<ApiResponse<InventoryOperation[]>> => {
    return getAssetHistory(accessToken ?? "", assetId);
  };

  const getStatusFunc = async (): Promise<ApiResponse<null>> => {
    return getStatus();
  };

  const getStudentImageFunc = async (
    studentId: number
  ): Promise<ApiResponse<string>> => {
    return getStudentImage(accessToken ?? "", studentId);
  };

  const getSiteMetricsFunc = async (
    siteId: number
  ): Promise<ApiResponse<SiteMetrics[]>> => {
    return getSiteMetrics(accessToken ?? "", siteId);
  };

  const setStatusRepairFunc = async (
    serial: string,
    repairStatusId: number
  ): Promise<ApiResponse<null>> => {
    return setStatusRepair(accessToken ?? "", serial, repairStatusId);
  };

  const setStatusRetirementFunc = async (
    serial: string,
    retireStatusId: number
  ): Promise<ApiResponse<null>> => {
    return setStatusRetirement(accessToken ?? "", serial, retireStatusId);
  };

  const setStatusMissingFunc = async (
    serial: string,
    missingStatusId: number
  ): Promise<ApiResponse<null>> => {
    return setStatusMissing(accessToken ?? "", serial, missingStatusId);
  };

  const getAbsoluteDataFunc = async (
    serial: string
  ): Promise<ApiResponse<InventoryAbsoluteData>> => {
    return getAbsoluteData(accessToken ?? "", serial);
  };

  const getUnlockCodeFunc = async (
    serial: string
  ): Promise<ApiResponse<string>> => {
    return getUnlockCode(accessToken ?? "", serial);
  };

  const reportDamagedFunc = async (
    serial: string,
    damageDescription: string
  ): Promise<ApiResponse<null>> => {
    return reportDamaged(accessToken ?? "", serial, damageDescription);
  };

  const reportMissingFunc = async (
    serial: string,
    details: string
  ): Promise<ApiResponse<null>> => {
    return reportMissing(accessToken ?? "", serial, details);
  };

  const getUserPermissionsFunc = async (
    goalId: number,
    email: string
  ): Promise<ApiResponse<UserPermissions>> => {
    return getUserPermissions(accessToken ?? "", goalId, email);
  };

  const validateSerialNumbersFunc = async (
    serialNumbers: string[]
  ): Promise<ApiResponse<string[]>> => {
    return validateSerialNumbers(accessToken ?? "", serialNumbers);
  };

  const bulkAssignSiteFunc = async (
    serialNumbers: string[],
    siteId: number
  ): Promise<ApiResponse<null>> => {
    return bulkAssignSite(accessToken ?? "", serialNumbers, siteId);
  };

  const bulkSetRepairFunc = async (
    serialNumbers: string[],
    repairStatusId: number
  ): Promise<ApiResponse<null>> => {
    return bulkSetRepair(accessToken ?? "", serialNumbers, repairStatusId);
  };

  const bulkSetRetirementFunc = async (
    serialNumbers: string[],
    retireStatusId: number
  ): Promise<ApiResponse<null>> => {
    return bulkSetRetirement(accessToken ?? "", serialNumbers, retireStatusId);
  };

  const bulkSetMissingFunc = async (
    serialNumbers: string[],
    missingStatusId: number
  ): Promise<ApiResponse<null>> => {
    return bulkSetMissing(accessToken ?? "", serialNumbers, missingStatusId);
  };

  const bulkSetSiteUseOnlyFunc = async (
    serialNumbers: string[],
    value: boolean
  ): Promise<ApiResponse<null>> => {
    return bulkSetSiteUseOnly(accessToken ?? "", serialNumbers, value);
  };

  const getLaptopReportFunc = async (): Promise<ApiResponse<null>> => {
    return getLaptopReport(accessToken ?? "");
  };

  const getHotspotReportFunc = async (): Promise<ApiResponse<null>> => {
    return getHotspotReport(accessToken ?? "");
  };

  const sendAssetsFunc = async (serialNumbers: string[], originSiteId: number, destinationSiteId: number): Promise<ApiResponse<null>> => {
    return sendAssets(accessToken ?? "", serialNumbers, originSiteId, destinationSiteId);
  }

  const receiveAssetsFunc = async (serialNumbers: string[], siteId: number): Promise<ApiResponse<null>> => {
    return receiveAssets(accessToken ?? "", serialNumbers, siteId);
  }

  const userHasAssetTypeFunc = async (userId: number, serial: string): Promise<ApiResponse<InventoryObject[]>> => {
    return userHasAssetType(accessToken ?? "", userId, serial);
  }

  return {
    accessToken,
    getInventoryObject: getInventoryObjectFunc,
    getInventoryObjectsBySiteId: getInventoryObjectsBySiteIdFunc,
    checkIn: checkInFunc,
    checkOut: checkOutFunc,
    getSites: getSitesFunc,
    getAllUsers: getAllUsersFunc,
    getUserById: getUserByIdFunc,
    getUserByEmail: getUserByEmailFunc,
    searchUsers: searchUsersFunc,
    getAllStaff: getAllStaffFunc,
    getStaffById: getStaffByIdFunc,
    searchStaff: searchStaffFunc,
    searchStudents: searchStudentsFunc,
    searchAssets: searchAssetsFunc,
    getAssetHistory: getAssetHistoryFunc,
    getStatus: getStatusFunc,
    getStudentImage: getStudentImageFunc,
    getSiteMetrics: getSiteMetricsFunc,
    setStatusRepair: setStatusRepairFunc,
    setStatusRetirement: setStatusRetirementFunc,
    setStatusMissing: setStatusMissingFunc,
    getAbsoluteData: getAbsoluteDataFunc,
    getUnlockCode: getUnlockCodeFunc,
    reportDamaged: reportDamagedFunc,
    getUserPermissions: getUserPermissionsFunc,
    validateSerialNumbers: validateSerialNumbersFunc,
    bulkAssignSite: bulkAssignSiteFunc,
    bulkSetRepair: bulkSetRepairFunc,
    bulkSetRetirement: bulkSetRetirementFunc,
    bulkSetMissing: bulkSetMissingFunc,
    bulkSetSiteUseOnly: bulkSetSiteUseOnlyFunc,
    getLaptopReport: getLaptopReportFunc,
    getHotspotReport: getHotspotReportFunc,
    reportMissing: reportMissingFunc,
    sendAssets: sendAssetsFunc,
    receiveAssets: receiveAssetsFunc,
    userHasAssetType: userHasAssetTypeFunc
  };
}
