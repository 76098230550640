import React from 'react';

import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';

import CacheManagement from '../CacheManagement';

interface FooterProps {
  onShowLog: () => void;
}

const Footer: React.FC<FooterProps> = ({ onShowLog }) => {
  const footerItems = [
    {
        template: () => {
            return (
                //<CacheManagement></CacheManagement>
                <></>
            );
        }
    }
  ];

    const endItems = <Button label="Show Log" icon="pi pi-history" className="xl:hidden" onClick={() => onShowLog()}/>;

  return (
    <Menubar model={footerItems} end={endItems} />
  );
};

export default Footer;
