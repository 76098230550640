interface AssetLocationPanelProps {
    latitude: number,
    longitude: number,
    deviceName: string
}

const AssetLocationPanel: React.FC<AssetLocationPanelProps> = ({ latitude, longitude, deviceName }) => {
    const locationLink = "https://www.bing.com/maps" +
        (!latitude && !longitude ? "" : `?sp=point.${encodeURIComponent(longitude)}_${encodeURIComponent(latitude)}_${encodeURIComponent(deviceName)}&cp=${encodeURIComponent(longitude)}~${encodeURIComponent(latitude)}&level=16`);

    return (
        <div>
            <a href={locationLink} target="_blank">View On Bing Maps</a>
        </div>
    );
};

export default AssetLocationPanel;