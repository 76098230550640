import { InventoryAbsoluteData } from "../apis/armoryApi";
import AssetLocationPanel from "./AssetLocationPanel";

interface DataListElementProps {
  label: string;
  value: any;
}

const DataListElement: React.FC<DataListElementProps> = ({
  label,
  value,
}) => {
  return (
    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
      <div className="text-500 w-6 md:w-3 font-medium">{label}</div>
      <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
        {value}
      </div>
    </li>
  );
};

interface AssetAbsoluteDataPanelProps {
  absoluteData: InventoryAbsoluteData;
}

const AssetAbsoluteDataPanel: React.FC<AssetAbsoluteDataPanelProps> = ({ absoluteData }) => {
  const lastConnected = absoluteData.lastConnected ? new Date(absoluteData.lastConnected).toLocaleString() : null;
  console.log(lastConnected);
  return (
    <div>
      <ul className="list-none p-0 m-0">
        <DataListElement label="Device Name" value={absoluteData.name} />
        <DataListElement label="Current User" value={absoluteData.currentUsername} />
        <DataListElement label="Last Connected" value={lastConnected ?? "Never"} />
        <DataListElement label="Frozen?" value={absoluteData.isFrozen ? "Yes" : "No"} />
        {absoluteData.isFrozen && (
          <>
            <DataListElement label="Unlock Code" value={absoluteData.unlockCode} />
          </>
        )}
        {absoluteData.latitude && absoluteData.longitude && (
          <DataListElement label="Asset Location" value={<AssetLocationPanel latitude={absoluteData.latitude} longitude={absoluteData.longitude} deviceName={absoluteData.name} />} />
        )}
      </ul>
    </div>
  );
};

export default AssetAbsoluteDataPanel;
