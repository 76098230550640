import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';


export function useAuthHelper() {
    const { login, logout } = useAuth();
    const navigate = useNavigate();

    const loginFunc = async () => {
        try {
            const isLoggedIn = await login();

            if (isLoggedIn) {
                navigate('/'); // Redirect to home page upon successful login
            }

        } catch (error) {
            console.error("Login failed:", error);
        }
    }

    const logoutFunc = async () => {
        logout();
    }

    return {
        login: loginFunc,
        logout: logoutFunc
    }
}