// apiClient.ts
export interface ApiResponse<T> {
    statusCode: number;
    data: T | null;
    error: string | null;
}

export interface InventoryObject {
    id: number;
    serial: string;
    site?: Site;
    siteId?: number;
    repairStatusId?: number;
    repairStatus?: DbEnum;
    retirementStatusId?: number;
    retirementStatus?: DbEnum;
    missingStatusId?: number;
    missingStatus?: DbEnum;
    transitSiteId?: number;
    transitSite?: Site;
    assetSkuId: number;
    assetSku: InventoryObjectSku;
    siteSchoolYear?: number;
    purchaseDate: Date;
    purchasePrice: number;
    lesseeId?: number;
    lessee?: {
        name: string;
    };
	status: string;
}

export interface DbEnum {
    name: string;
    tooltip?: string;
}

export interface InventoryAbsoluteData {
    currentUsername: string;
    isFrozen: boolean;
    unlockCode: string | null;
    name: string;
    lastConnected: Date | null;
    latitude?: number;
    longitude?: number;
}

export interface InventoryObjectSku{
    id: number;
    type: string;
    make: string;
    model: string;
    modelNumber: string;
}

export interface InventoryObjectTableEntry {
    id: number;
    serial: string;
    skuName?: string;
    leseeName?: string;
    status: string;
}

export interface InventorySearchModel {
    RepairStatusId: number | null;
    RetirementStatusId: number | null;
    TransitSiteId: number | null;
    AssetSkuId: number | null;
    SiteSchoolYear: number | null;
    LesseeId: number | null;
}

export interface InventoryOperation {
    id: number;
    inventoryObjectId: number | null;
    actorId: number;
    actionId: number;
    date: Date;
    success: boolean;
    notes: string | null;
    payload: string | null;
}

export interface Site {
    id: number;
    name: string;
    region: Region | null;
}

export interface Region {
    id: number;
    name: string;
}

export interface User {
    id: number;
    goalId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    homeSite: Site;
    isActive: boolean;
}

export interface Staff extends User{
    position: Position;
}

export interface Student extends User { }

export interface Position {
    id: number;
    name: string;
}

export interface UserIdNameSearchResult {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    type: string;
    fullName: string;
}

export interface StaffIdNameSearchResult extends UserIdNameSearchResult {
    position: Position;
}

export interface StudentIdNameSearchResult extends UserIdNameSearchResult { }

export interface SiteMetrics {
    siteId: number;
    schoolYear: number;
    type: string;
    total: number;
    available: number;
    siteUseOnly: number;
    needsRepair: number;
    missing: number;
    retired: number;
    inTransit: number;
    checkedOut: number;
    reportTime: Date;
}

export interface UserPermissions{
    canCheckOut: boolean;
    canCheckIn: boolean;
    canChangeStatus: boolean;
    isAdmin: boolean;
    isActive: boolean;
}

//type guards
export const isStaff = (user: User): user is Staff => {
  return "position" in user;
};

// Generic request function
async function apiRequest<T>(url: string, token: string, options: RequestInit = {}): Promise<ApiResponse<T>> {
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            const error = await response.text();
            return { statusCode: response.status, data: null, error };
        }

        const text = await response.text();
        const data: T = text && text.length > 0 ? JSON.parse(text) : null;
        return { statusCode: response.status, data, error: null };
    } catch (error) {
        console.error('Fetch operation error:', error);
        return { statusCode: 400, data: null, error: error instanceof Error ? error.message : String(error) };
    }
}

async function apiFileRequest(url: string, token: string, filename: string, options: RequestInit = {}): Promise<ApiResponse<null>> {
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${token}`,
            }
        });

        if (!response.ok) {
            const error = await response.text();
            return { statusCode: response.status, data: null, error };
        }

        // Get the blob from the response
        const blob = await response.blob();

        // Generate a formatted date string
        const date = new Date();
        const formattedDate = date.toISOString().split('T')[0]; // e.g., 2024-04-27

        // Create a download URL for the blob
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create an anchor element and trigger the download
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        // Clean up by removing the anchor and revoking the object URL
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);

        return { statusCode: response.status, data: null, error: null };
    } catch (error) {
        console.error('Fetch operation error:', error);
        return { statusCode: 400, data: null, error: error instanceof Error ? error.message : String(error) };
    }
}

export async function getInventoryObject(token: string, serial: string): Promise<ApiResponse<InventoryObject>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/get/${serial}`;
    const response = await apiRequest<InventoryObject>(url, token);
    if (response.data) {
        response.data.purchaseDate = new Date(response.data.purchaseDate);
    }
    return response;
}

export async function getInventoryObjectsBySiteId(token: string, siteId: number, includeUnavailable: boolean): Promise<ApiResponse<InventoryObjectTableEntry[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/getbysiteid/${siteId}?includeUnavailable=${includeUnavailable}`;
    return apiRequest<InventoryObjectTableEntry[]>(url, token);
}

export async function checkIn(token: string, serial: string, siteId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/checkin/?serial=${serial}&siteId=${siteId}`;
    return apiRequest<null>(url, token);
}

export async function checkOut(token: string, lesseeId: number, lesseeName: string, serial: string): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/checkout/?lesseeId=${lesseeId}&lesseeName=${lesseeName}&serial=${serial}`;
    return apiRequest<null>(url, token);
}

export async function getSites(token: string): Promise<ApiResponse<Site[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/sites`;
    return apiRequest<Site[]>(url, token);
}

export async function getAllUsers(token: string): Promise<ApiResponse<User[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/users`;
    return apiRequest<User[]>(url, token);
}

export async function searchUsers(token: string, filter: string): Promise<ApiResponse<UserIdNameSearchResult[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/users?filter=${filter}`;
    return apiRequest<UserIdNameSearchResult[]>(url, token);
}

export async function getUserById(token: string, userId: number): Promise<ApiResponse<User>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/users/${userId}`;
    return apiRequest<User>(url, token);
}

export async function getUserByEmail(token: string, email: string): Promise<ApiResponse<User>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/users/${email}`;
    return apiRequest<User>(url, token);
}

export async function getUserPermissions(token: string, goalId: number, email: string): Promise<ApiResponse<UserPermissions>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/users/getpermissions/?goalId=${goalId}&email=${email}`;
    return apiRequest<UserPermissions>(url, token);
}

export async function getAllStaff(token: string): Promise<ApiResponse<Staff[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/staff`;
    return apiRequest<Staff[]>(url, token);
}

export async function searchStaff(token: string, filter: string): Promise<ApiResponse<StaffIdNameSearchResult[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/staff?filter=${filter}`;
    return apiRequest<StaffIdNameSearchResult[]>(url, token);
}

export async function searchStudents(token: string, filter: string): Promise<ApiResponse<StudentIdNameSearchResult[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/students?filter=${filter}`;
    return apiRequest<StudentIdNameSearchResult[]>(url, token);
}

export async function getStaffById(token: string, userId: number): Promise<ApiResponse<Staff>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/staff/${userId}`;
    return apiRequest<Staff>(url, token);
}

export async function searchAssets(token: string, searchModel: InventorySearchModel): Promise<ApiResponse<InventoryObjectTableEntry[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/search`;
    return apiRequest<InventoryObjectTableEntry[]>(url, token, {
        method: 'POST',
        body: JSON.stringify(searchModel),
    });
}

export async function getAssetHistory(token: string, assetId: number): Promise<ApiResponse<InventoryOperation[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/getassethistory/${assetId}`;
    const response = await apiRequest<InventoryOperation[]>(url, token);

    if (response.data) {
        response.data.forEach(operation => {
            operation.date = new Date(operation.date);
        });
    }
    
    return response;
}

export async function getStatus(): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/status`;
    return apiRequest<null>(url, "", {
        method: 'HEAD'
    });
}

export async function getStudentImage(token: string, studentId: number): Promise<ApiResponse<string>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/students/${studentId}/image`;
    const response = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });

    if (!response.ok) {
        const error = await response.text();
        return { statusCode: response.status, data: null, error };
    }

    const data = await response.text();

    return { statusCode: response.status, data, error: null};
} 

export async function getSiteMetrics(token: string, siteId: number): Promise<ApiResponse<SiteMetrics[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/sites/${siteId}/metrics`;
    return apiRequest<SiteMetrics[]>(url, token);
}

export async function userHasAssetType(token: string, userId: number, serial: string): Promise<ApiResponse<InventoryObject[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/userHasAssetType?userId=${userId}&serial=${serial}`;
    return apiRequest<InventoryObject[]>(url, token);
}

export async function setStatusRepair(token: string, serial: string, repairStatusId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/${serial}/status/repair`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(repairStatusId),
    });
}

export async function setStatusRetirement(token: string, serial: string, retireStatusId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/${serial}/status/retirement`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(retireStatusId),
    });
}

export async function setStatusMissing(token: string, serial: string, missingStatusId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/${serial}/status/missing`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(missingStatusId),
    });
}

export async function reportDamaged(token: string, serial: string, damageDescription: string): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/${serial}/damaged`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(damageDescription),
    });
}

export async function reportMissing(token: string, serial: string, details: string): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/${serial}/missing`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(details),
    });
}

export async function getAbsoluteData(token: string, serial: string): Promise<ApiResponse<InventoryAbsoluteData>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/getabsolutedata/${serial}`;
    return apiRequest<InventoryAbsoluteData>(url, token);
}

export async function getUnlockCode(token: string, serial: string): Promise<ApiResponse<string>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/getunlockcode/${serial}`;
    return apiRequest<string>(url, token);
}

export async function validateSerialNumbers(token: string, serials: string[]): Promise<ApiResponse<string[]>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/validate`;
    return apiRequest<string[]>(url, token, {
        method: 'POST',
        body: JSON.stringify(serials.filter((serial) => serial.trim() !== "").join('|')),
    });
}

export async function bulkAssignSite(token: string, serials: string[], siteId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/bulkassignsite?siteId=${siteId}`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(serials.filter((serial) => serial.trim() !== "").join('|')),
    });
}

export async function bulkSetRepair(token: string, serials: string[], repairStatusId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/bulksetrepair?repairStatusId=${repairStatusId}`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(serials.filter((serial) => serial.trim() !== "").join('|')),
    });
}

export async function bulkSetRetirement(token: string, serials: string[], retireStatusId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/bulksetretirement?retireStatusId=${retireStatusId}`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(serials.filter((serial) => serial.trim() !== "").join('|')),
    });
}

export async function bulkSetMissing(token: string, serials: string[], missingStatusId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/bulksetmissing?missingStatusId=${missingStatusId}`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(serials.filter((serial) => serial.trim() !== "").join('|')),
    });
}

export async function bulkSetSiteUseOnly(token: string, serials: string[], value: boolean): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/bulksetsiteuseonly?value=${value}`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(serials.filter((serial) => serial.trim() !== "").join('|')),
    });
}

export async function getLaptopReport(token: string): Promise<ApiResponse<null>> {
    //calculate file name with date
    const date = new Date();
    const formattedDate = date.toISOString();
    const filename = `Laptop Inventory Report ${formattedDate}.csv`;

    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/laptopreport`;
    
    return apiFileRequest(url, token, filename);
}

export async function getHotspotReport(token: string): Promise<ApiResponse<null>> {
    //calculate file name with date
    const date = new Date();
    const formattedDate = date.toISOString();
    const filename = `Hotspot Inventory Report ${formattedDate}.csv`;

    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/hotspotreport`;
    
    return apiFileRequest(url, token, filename);
}

export async function sendAssets(token: string, serials: string[], originSiteId: number, destinationSiteId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/sendassets?originSiteId=${originSiteId}&destinationSiteId=${destinationSiteId}`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(serials.filter((serial) => serial.trim() !== "").join('|')),
    });
}

export async function receiveAssets(token: string, serials: string[], siteId: number): Promise<ApiResponse<null>> {
    const url = `${process.env.REACT_APP_ARMORY_BASE_URL}/inventory/receiveassets?siteId=${siteId}`;
    return apiRequest<null>(url, token, {
        method: 'POST',
        body: JSON.stringify(serials.filter((serial) => serial.trim() !== "").join('|')),
    });
}