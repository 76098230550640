import { useState } from "react";
import CheckOutPanel from "../components/CheckOutPanel";
import { Fieldset } from "primereact/fieldset";
import { Student } from "../apis/armoryApi";
import UserProfileComponent from "../components/UserProfileComponent";

const CheckOutPage: React.FC = () => {
    const [student, setStudent] = useState<Student | null>();

    const onStudentChange = (currentStudent: Student | null) => {
        setStudent(currentStudent);
    };

    return (
        <div className="m-4 mb-2">
            <div className="grid">
                <div className="col-12 md:col-4">
                    <Fieldset legend="Check Out Asset">
                        <CheckOutPanel onStudentChange={onStudentChange} />
                    </Fieldset>
                </div>
                <div className="col-12 md:col-8">
                    {student && <Fieldset legend="User Profile">
                        <UserProfileComponent userId={student.id} /> 
                    </Fieldset>}
                </div>                
            </div>
        </div>
    );
};

export default CheckOutPage;