import { useArmory } from "../../contexts/ArmoryContext";
import { useAuth } from "../../contexts/AuthContext";

import { useAuthHelper } from "../../hooks/useAuthHelper";

import { useNavigate } from "react-router-dom";

import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";
import { Button } from "primereact/button";

import "primeicons/primeicons.css";
import ArmorySiteDropdown from "../ArmorySiteDropdown";
import ThemeToggle from "../ThemeToggle";
import AdminPage from "../../pages/AdminPage";
import { useArmoryApi } from "../../hooks/useArmoryApi";
import { useEffect, useState } from "react";



const Ribbon: React.FC = () => {
  const { site, userGid, userPerms } = useArmory();
  const { getStaffById } = useArmoryApi();

  const { account } = useAuth();
  const { logout } = useAuthHelper();

  const [isAdmin, setIsAdmin] = useState(false); 

  const navigate = useNavigate();

  const checkAdminStatus = async () => {
      setIsAdmin(userPerms.isAdmin);
  };

  const menuItems: MenuItem[] = [
    {
      icon: "pi pi-arrow-left",
      command: () => navigate(-1),
    },
    {
      icon: "pi pi-home",
      label: "Home",
      command: () => navigate("/"),
    },
    {
      icon: "pi pi-arrow-up",
      label: "Check Out",
      command: () => navigate("/check-out"),
    },
    {
      icon: "pi pi-arrow-down",
      label: "Check In",
      command: () => navigate("/check-in"),
    },
    {
      icon: "pi pi-arrow-right-arrow-left",
      label: "Inventory Transfer",
      command: () => navigate("/stock"),
    },
    ...(isAdmin ? [{
        icon: "pi pi-clipboard",
        label: "Administration",
        command: () => navigate("/admin"),
    }] : []),
    {
      template: () => {
        return <ThemeToggle />;
      },
      className: "ml-auto pr-3"
    },
    {
      icon: 'pi pi-user',
      label: `${account?.name ?? "N/A"}`,
      items: [
        {
          label: "Profile",
          command: () => navigate("/me"),
        },
        {
          label: "Sign Out",
          command: () => logout(),
        },
      ],
    }   
  ];

  useEffect(() => {
      checkAdminStatus();
  }
  , [userPerms]);

    return <Menubar model={menuItems} start={<ArmorySiteDropdown/>} pt={{
    menu: {
      className: 'w-full'
    }
  }} />;
};

export default Ribbon;
