import React, { useEffect } from "react";

import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";

interface CheckboxListProps {
  options: string[];
  checkedItems: string[];
  onChange: (selectedOptions: string[]) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = ({ options, checkedItems, onChange }) => {

  useEffect(() => {
    onChange(checkedItems);
  }, []);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { value, checked } = e;
    let updatedCheckedItems = [...checkedItems];

    if (checked) {
      updatedCheckedItems.push(value);
    } else {
      updatedCheckedItems = updatedCheckedItems.filter(
        (item) => item !== value
      );
    }

    onChange(updatedCheckedItems);
  };

  return (
    <div className="flex flex-column flex-wrap justify-content-center gap-3">
      {options.map((option, index) => (
        <div key={index} className="flex align-items-center">
          <Checkbox
            inputId={`checkbox-${index}`}
            name={option}
            value={option}
            onChange={handleCheckboxChange}
            checked={checkedItems.includes(option)}
          />
          <label htmlFor={`checkbox-${index}`} className="ml-2">
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
