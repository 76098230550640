import { useEffect, useRef, useState } from "react";

import { InventoryObjectTableEntry } from "../../apis/armoryApi";
import { useArmory, useArmoryDispatch } from "../../contexts/ArmoryContext";

import CheckboxList from "../CheckboxList";

import { DataTable, DataTableFilterMeta, SortOrder } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Fieldset } from "primereact/fieldset";
import { OverlayPanel } from "primereact/overlaypanel";
import { Skeleton } from "primereact/skeleton";
import { ContextMenu } from "primereact/contextmenu";
import { useNavigate } from "react-router-dom";

interface ColumnMeta {
  header: string;
  body?: any;
  field?: string;
  filter?: boolean;
  filterPlaceholder?: string;
  isSortable: boolean;
  isVisible: boolean;
  isCopyable: boolean;
}

interface InventoryObjectDataTableProps {
  isLoading: boolean;
  inventoryObjectsData: InventoryObjectTableEntry[];
  onRefreshButtonClick: () => void;
}

const InventoryObjectDataTable: React.FC<InventoryObjectDataTableProps> = ({
  isLoading,
  inventoryObjectsData,
  onRefreshButtonClick,
}) => {
  const { isOnline, filters, sort, inventoryTablePage } = useArmory();
  const dispatch = useArmoryDispatch();
  const navigate = useNavigate();

  const op = useRef<OverlayPanel>(null);
  const dt = useRef<DataTable<InventoryObjectTableEntry[]>>(null);
  const cm = useRef<ContextMenu>(null);

  const columns: ColumnMeta[] = [
    {
      header: "Manage",
      field: "id",
      body: (rowData: InventoryObjectTableEntry) => (
        <Button
          icon="pi pi-cog"
          className="p-button-rounded p-button-info"
          title={`Manage Asset ${rowData.serial}`}
          tooltip={`Manage Asset ${rowData.serial}`}
          tooltipOptions={{ position: "top" }}
          onClick={() => navigate(`/asset/${rowData.serial}`)}
        />
      ),
      filter: false,
      isSortable: false,
      isVisible: true,
      isCopyable: false
    },
    {
      header: "ID",
      field: "id",
      filter: true,
      filterPlaceholder: "Search by Asset ID",
      isSortable: true,
      isVisible: false,
      isCopyable: true,
    },
    {
      header: "Type",
      field: "skuName",
      filter: true,
      filterPlaceholder: "Search by Type",
      isSortable: true,
      isVisible: true,
      isCopyable: true
    },
    {
      header: "Serial",
      field: "serial",
      filter: true,
      filterPlaceholder: "Search by Asset Serial",
      isSortable: true,
      isVisible: true,
      isCopyable: true
    },
    {
      header: "Lessee Name",
      field: "lesseeName",
      filter: true,
      filterPlaceholder: "Search by Lessee Name",
      isSortable: true,
      isVisible: true,
      isCopyable: true
    },
    {
      header: "Status",
      field: "status",
      filter: true,
      filterPlaceholder: "Search by Status",
      isSortable: true,
      isVisible: true,
      isCopyable: true
    }
  ];

  const [inventoryObjects, setInventoryObjects] = useState(inventoryObjectsData);

  const [selectedRow, setSelectedRow] = useState<InventoryObjectTableEntry | null>(null);

  const [visibleColumns, setVisibleColumns] = useState(columns);

  const [checkedItems, setCheckedItems] = useState<string[]>(
    columns.filter((col) => col.isVisible).map((col) => col.header)
  );
  const filterObject: Record<string, any> = {...filters}
  const [globalFilterValue, setGlobalFilterValue] = useState<string>(filterObject["global"].value);

  const [currentFilters, setCurrentFilters] = useState<DataTableFilterMeta>(filters);
  const [sortField, setSortField] = useState(sort.sortField);
  const [sortOrder, setSortOrder] = useState<SortOrder>(sort.sortOrder);
  const [page, setPage] = useState<number>(inventoryTablePage);

  useEffect(() => {
    setInventoryObjects(inventoryObjectsData);
    setVisibleColumns(columns.filter((col) => col.isVisible));
  }, [inventoryObjectsData]);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...currentFilters };

    // @ts-ignore
    _filters["global"].value = value;

    setCurrentFilters(_filters);
    setGlobalFilterValue(value);

	dispatch({type: "SET_FILTERS", payload: {_filters}});
  };

  const handleColumnVisibilityChange = (selectedColumns: string[]) => {
    const updatedColumns = columns.map((col) => ({
      ...col,
      isVisible: selectedColumns.includes(col.header),
    }));

    setCheckedItems(selectedColumns);
    setVisibleColumns(updatedColumns.filter((col) => col.isVisible));
  };

  const handleFilterChange = (e: any) => {
	  setCurrentFilters(e.filters);

	  dispatch({type: "SET_FILTERS", payload: e.filters});
  };

  const handleSortChange = (e: any) => {
	  setSortField(e.sortField);
	  setSortOrder(e.sortOrder);

	  dispatch({type: "SET_SORT", payload: {sortField: e.sortField, sortOrder: e.sortOrder}});
  };

  const handlePageChange = (e: any) => {
	  const pageNumber = e.first/e.rows;
	  setPage(pageNumber);

	  dispatch({type: "SET_VALUE", payload: {key: "inventoryTablePage", value: pageNumber}});
  }

  const onClearFilter = () => {
    setCurrentFilters(filters);
    setGlobalFilterValue('');

	dispatch({type: "CLEAR_FILTERS"});
  };

  const exportCSV = (selectionOnly: any) => {
    if (dt !== null && dt.current !== null) {
      dt.current.exportCSV({ selectionOnly });
    }
  };

  // const lesseeStringBodyTemplate = (inventoryObject: InventoryObjectTableEntry) => {
  //     return `${inventoryObject.leseeName ?? "N/A"}`;
  // };

  // const availabilityStatusBodyTemplate = (inventoryObject: InventoryObjectTableEntry) => {
  //     if (inventoryObject.leseeName) {
  //         return "Checked Out";
  //     } else {
  //         return "Checked In";
  //     }
  // }

  const copyToClipboard = (key: string) => {
    if (selectedRow) {
      const value = selectedRow[key as keyof InventoryObjectTableEntry];
      if (value !== undefined) {
        navigator.clipboard.writeText(value.toString())
          .then(() => {
            console.log('Text copied to clipboard: ', value);
          })
          .catch(err => {
            console.error('Error copying text to clipboard:', err);
          });
      }
    }
  };

  const getCopyMenuItems = () => {
    return visibleColumns.filter(col => col.isCopyable).map((col) => {
      return { label: col.header, command: () => copyToClipboard(col.field ?? '') }
    })
  }

  const menuModel = [
    {
      label: 'Manage',
      icon: 'pi pi-fw pi-cog',
      command: () => navigate(`/asset/${selectedRow?.serial}`)
    },
    {
      separator: true
    },
    {
      label: 'Copy',
      icon: 'pi pi-fw pi-copy',
      items: getCopyMenuItems()
    }
  ];

  const items = Array.from({ length: 10 }, (v, i) => ({
    code: `Code ${i}`,
    name: `Name ${i}`,
    category: `Category ${i}`,
    quantity: i * 10,
  }));

  if (isLoading) {
    return (
      <div>
        <div className="mb-3">
          <Skeleton width="100%" height="4rem"></Skeleton>
        </div>
        <div>
          <DataTable value={items} showGridlines>
            {visibleColumns.map((col) => (
              <Column
                key={col.header}
                header={col.header}
                body={<Skeleton />}
              />
            ))}
          </DataTable>
        </div>
      </div>
    );
  }

  return (
    <div className="fade-in">
      <div className="mb-3">
        <Fieldset legend="Actions">
          <div className="flex flex-row gap-2 justify-content-between">
            <div className="">
              <IconField iconPosition="left" className="p-fluid">
                <InputIcon className="pi pi-search" />
                <InputText
                  placeholder="Search"
                  value={globalFilterValue ?? ""}
                  onChange={onGlobalFilterChange}
                  title="Global Filter"
                />
              </IconField>
            </div>
            <div>
              <Button
                className="clear-filter-button mr-2"
                icon="pi pi-filter-slash"
                title="Clear Filters"
                tooltip="Clear Filters"
                tooltipOptions={{ position: "top" }}
                onClick={onClearFilter}
              />

              <Button
                className="refresh-button mr-2"
                icon="pi pi-refresh"
                visible={isOnline}
                title="Refresh Data"
                tooltip="Refresh Data"
                tooltipOptions={{ position: "top" }}
                onClick={onRefreshButtonClick}
              />

              <Button
                className="csv-button mr-2"
                icon="pi pi-file-export"
                title="Export CSV"
                tooltip="Export CSV"
                tooltipOptions={{ position: "top" }}
                onClick={() => exportCSV(false)}
              />

              <Button
                type="button"
                icon="pi pi-list"
                title="Show / Hide Column"
                tooltip="Show / Hide Column"
                tooltipOptions={{ position: "top" }}
                onClick={(e) => {
                  if (op.current) op.current.toggle(e);
                }}
              />

              <OverlayPanel ref={op}>
                <CheckboxList
                  options={columns.map((col) => col.header)}
                  checkedItems={checkedItems}
                  onChange={handleColumnVisibilityChange}
                />
              </OverlayPanel>
            </div>
            {/* <div>
              <MultiSelect
                value={visibleColumns}
                options={columns}
                optionLabel="header"
                onChange={onColumnToggle}
                className="w-full"
                display="chip"
              />
            </div> */}
          </div>
        </Fieldset>
      </div>

      <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedRow(null)} />
      <DataTable
        ref={dt}
        value={inventoryObjects}
        filters={currentFilters}
        filterDisplay="menu"
		sortField={sortField}
		sortOrder={sortOrder}
        showGridlines
        paginator
		first={page*10}
        rows={10}
        removableSort
        onContextMenu={(e) => cm.current?.show(e.originalEvent)}
        contextMenuSelection={selectedRow as object | undefined}
        onContextMenuSelectionChange={(e) => setSelectedRow(e.value)}
		onFilter={handleFilterChange}
		onSort={handleSortChange}
		onPage={handlePageChange}
      >
        {visibleColumns.map((col) => (
          <Column
            key={col.header}
            field={col.field}
            header={col.header}
            sortable={col.isSortable}
            body={col.body}
            filter={col.filter}
            filterPlaceholder={col.filterPlaceholder}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default InventoryObjectDataTable;
