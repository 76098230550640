import { useEffect, useState } from 'react';

import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primereact/autocomplete';

import { useArmoryApi } from '../hooks/useArmoryApi';
import { Student, StudentIdNameSearchResult } from '../apis/armoryApi';
import { Chip } from 'primereact/chip';

interface StudentSearchAutoCompleteProps {
    label: string;
    student: Student | null;
    setStudent: (student: Student | null) => void;
}

const StudentSearchAutoComplete: React.FC<StudentSearchAutoCompleteProps> = ({ label, student, setStudent }) => {
    const { searchStudents } = useArmoryApi();

    const [value, setValue] = useState('');
    const [searchResults, setSearchResults] = useState<StudentIdNameSearchResult[]>();

    const search = async (event: AutoCompleteCompleteEvent) => {
        const filter = event.query.toLowerCase();

        const response = await searchStudents(filter);

        const suggestions: StudentIdNameSearchResult[] = response.data?.map((user: StudentIdNameSearchResult) => {
            const fullName = `${user.firstName} ${user.lastName}`;
            user.fullName = fullName;
            return user;
        }) ?? [];

        setSearchResults(suggestions)
    }

    const itemTemplate = (user: StudentIdNameSearchResult) => {
        return (
            <div key={user.id} className="flex align-items-center">
                <div>{user.firstName} {user.lastName} ({user.emailAddress})</div>
            </div>
        );
    };

    useEffect(() => {
        setStudent(student);
        setValue('');
        setSearchResults([]);
    }, [student]);

    if (student) {
        return <Chip label={`${student.firstName} ${student.lastName}`} removable onRemove={(e) =>{
            setValue('');
            setStudent(null);
            setSearchResults([]);
        }}/>
    }

    return (
        <div>
            <label htmlFor='studentSearchAutoCompleteInput'>{label}</label>
            <div className='p-fluid'>
                <AutoComplete
                    inputId='studentSearchAutoCompleteInput'
                    value={value}
                    suggestions={searchResults}
                    field="fullName"
                    delay={1000}
                    itemTemplate={itemTemplate}
                    completeMethod={search}
                    onChange={(e) => {
                        setValue(e.value)
                    }}
                    onSelect={(e: AutoCompleteSelectEvent) => {
                        setStudent(e.value);
                    }}
                    placeholder="Type here to search for a Student"
                    tooltip="Search by Name or GID"
                />
            </div>
        </div>
    )
};

export default StudentSearchAutoComplete;