import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import {
  InventoryObject,
  InventoryOperation,
  InventoryAbsoluteData,
} from "../apis/armoryApi";
import { useArmory, useArmoryDispatch } from "../contexts/ArmoryContext";
import { useArmoryApi } from "../hooks/useArmoryApi";

import { Button } from "primereact/button";
import { Fieldset, FieldsetToggleEvent } from "primereact/fieldset";

import CheckInPanel from "../components/CheckInPanel";
import CheckOutPanel from "../components/CheckOutPanel";
import AssetDetailsPanel from "../components/AssetDetailsPanel";
import AssetAbsoluteDataPanel from "../components/AssetAbsoluteDataPanel";
import AssetHistoryPanel from "../components/AssetHistoryPanel";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

interface AssetDataListElementProps {
  label: string;
  value: any;
}

const AssetDataListElement: React.FC<AssetDataListElementProps> = ({
  label,
  value,
}) => {
  return (
    <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
      <div className="text-500 w-6 md:w-3 font-medium">{label}</div>
      <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
        {value}
      </div>
    </li>
  );
};

const AssetManagementPage: React.FC = () => {
  const [currentInventoryObject, setCurrentInventoryObject] =
    useState<InventoryObject>();
  const [historyCollapsed, setHistoryCollapsed] = useState(true);
  const [absoluteCollapsed, setAbsoluteCollapsed] = useState(true);
  const [assetHistory, setAssetHistory] = useState<InventoryOperation[]>();
  const [absoluteData, setAbsoluteData] = useState<InventoryAbsoluteData>();
  const { assetSerial } = useParams();
  const state = useArmory();
  const { isCursorLoading } = useArmory();
  const dispatch = useArmoryDispatch();
  const {
    getInventoryObject,
    getAssetHistory,
    getAbsoluteData,
    reportDamaged,
    reportMissing,
  } = useArmoryApi();
  const [damageDialogVisible, setDamageDialogVisible] = useState(false);
  const [damageDescription, setDamageDescription] = useState("");
  const [missingDialogVisible, setMissingDialogVisible] = useState(false);
  const [missingDetails, setMissingDetails] = useState("");

  //This triggers a page update whenever an action is added to the log
  useEffect(() => {
    if (!historyCollapsed) {
        getCurrentAssetHistory();
    }

    getCurrentInventoryObject();

  }, [state.log]);

  const getCurrentInventoryObject = async () => {
    if (assetSerial) {
      const apiResponse = await getInventoryObject(assetSerial);

      if (apiResponse.data) {
          setCurrentInventoryObject(apiResponse.data);
      }
    }
  };

  const getCurrentAssetHistory = async () => {
    if (currentInventoryObject) {
        const apiResponse = await getAssetHistory(currentInventoryObject.id);

        if (apiResponse.data) {
            setAssetHistory(apiResponse.data);
        }
    }
  };

  const handleAssetHistoryToggle = (event: FieldsetToggleEvent) => {
    getCurrentAssetHistory();

    setHistoryCollapsed(!historyCollapsed);
  };

  const handleAbsoluteToggle = (event: FieldsetToggleEvent) => {
    const getCurrentAbsoluteData = async () => {
      if (assetSerial) {
        const apiResponse = await getAbsoluteData(assetSerial);

        if (apiResponse.data) {
          setAbsoluteData(apiResponse.data);
        }
      }
    };

    getCurrentAbsoluteData();

    setAbsoluteCollapsed(!absoluteCollapsed);
  };

  const handleReportDamaged = async () => {
    dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});

    if (damageDescription) {
      if (assetSerial) {
        const apiResponse = await reportDamaged(assetSerial, damageDescription);
        
        if (apiResponse.statusCode === 200) {
          const successMsg: string = `Asset: ${assetSerial} - Report Damaged succeeded.`;
		  dispatch({type: "SHOW_TOAST", payload: {severity: "success", summary: "Success", detail: successMsg, life: 3000}});
	      dispatch({type: "ADD_LOG_ENTRY", payload: {message: successMsg}});
          setDamageDialogVisible(false);
        } else {
          const errorMsg: string = `Asset: ${assetSerial} - Report Damaged failed. ${apiResponse.error}`;
          dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
	      dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
        }
      } else {
		dispatch({type: "ADD_LOG_ENTRY", payload: {message: "Error: Missing asset serial when reporting damaged."}});
      } 
    } else {
		dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: "A description of the damage is required", life: 3000}});
    }

    setTimeout(() => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);
  };

  const handleReportMissing = async () => {
    dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});

    if (missingDetails) {
      if (assetSerial) {
        const apiResponse = await reportMissing(assetSerial, missingDetails);
        
        if (apiResponse.statusCode === 200) {
          const successMsg: string = `Asset: ${assetSerial} - Report Missing succeeded.`;
          dispatch({type: "SHOW_TOAST", payload: {severity: "success", summary: "Success", detail: successMsg, life: 3000}});
	      dispatch({type: "ADD_LOG_ENTRY", payload: {message: successMsg}});
          setMissingDialogVisible(false);
        } else {
          const errorMsg: string = `Asset: ${assetSerial} - Report Missing failed. ${apiResponse.error}`;
          dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
	      dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
        }
      } else {
		  dispatch({type: "ADD_LOG_ENTRY", payload: {message: "Error: Missing asset serial when reporting missing."}});
      } 
    } else {
		dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: "An explanation of why the asset is being reported missing is required", life: 3000}});
    }

    setTimeout(() => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);
  };

  const hasLessee = currentInventoryObject?.lesseeId ? true : false;

  const isDamaged = (currentInventoryObject?.repairStatusId ?? -1) > 0;
  const isMissing = (currentInventoryObject?.missingStatusId ?? -1) > 0;
  const isRetired = (currentInventoryObject?.retirementStatusId ?? -1) > 0;

    const handleDamageDialogHide = () => {
        setDamageDialogVisible(false);
    };
  
    const handleDamageDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDamageDescription(event.target.value);
    };

    const handleMissingDialogHide = () => {
        setMissingDialogVisible(false);
    };
  
    const handleMissingDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMissingDetails(event.target.value);
    };

  return (
    <div className="m-4 mb-2">
      <div className="flex flex-column md:flex-row gap-2 mb-2">
        <div className="w-full md:w-8">
          <Fieldset legend="Asset Details" className="mb-2">
            {currentInventoryObject && (
              <AssetDetailsPanel inventoryObject={currentInventoryObject} />
            )}
          </Fieldset>
          <Fieldset
            legend="Absolute"
            toggleable
            collapsed={absoluteCollapsed}
            onToggle={(event) => handleAbsoluteToggle(event)}
          >
            {absoluteData && (
              <AssetAbsoluteDataPanel absoluteData={absoluteData} />
            )}
          </Fieldset>
        </div>
        <div className="w-full md:w-4">
          {hasLessee === true && (
            <Fieldset legend="Check In Asset" className="mb-2">
              <CheckInPanel isDisabled assetSerial={assetSerial} />
            </Fieldset>
          )}
          {hasLessee === false && (
            <Fieldset legend="Check Out Asset" className="mb-2">
              <CheckOutPanel isDisabled assetSerial={assetSerial} />
            </Fieldset>
          )}
          {currentInventoryObject && (
            <Fieldset legend="Status" className="mb-2">
              <div>
                <ul className="list-none p-0 m-0">
                  <AssetDataListElement
                    label="Repair State"
                    value={currentInventoryObject.repairStatus?.name ?? "Undamaged"}
                  />
                  <AssetDataListElement
                    label="Missing State"
                    value={currentInventoryObject.missingStatus?.name ?? "Not Missing"}
                  />
                  <AssetDataListElement
                    label="Retirement State"
                    value={currentInventoryObject.retirementStatus?.name ?? "In Circulation"}
                  />
                </ul>
                {isDamaged === false && (
                  <Button
                    label="Report Damaged"
                    icon="pi pi-wrench"
                    severity="danger"
                    onClick={() => setDamageDialogVisible(true)}
                    loading={isCursorLoading}
                  />
                )}
                <Dialog header="Report Damaged" onHide={handleDamageDialogHide} visible={damageDialogVisible}>
                    <div  className="w-full">
                        <label htmlFor="damageDescriptionInputText">Description of Damage</label>
                        <div  className="w-full">
                          <InputTextarea id="damageDescriptionInputText" value={damageDescription} onChange={(e) => handleDamageDescriptionChange(e)}  className="w-full"/>
                        </div>
                    </div>
                    <Button icon="pi pi-wrench" severity="danger" label="Report Damaged" onClick={handleReportDamaged} className="mt-2 mr-2"/>
                    <Button label="Cancel" onClick={() => setDamageDialogVisible(false)} className="mt-2"/>
                </Dialog>
                {isMissing === false && (
                  <Button
                    label="Report Missing"
                    icon="pi pi-flag"
                    severity="warning"
                    onClick={() => setMissingDialogVisible(true)}
                    loading={isCursorLoading}
                  />
                )}
                <Dialog header="Report Missing" onHide={handleMissingDialogHide} visible={missingDialogVisible}>
                    <div  className="w-full">
                        <label htmlFor="missingReasonInputText">Reason for Report</label>
                        <div  className="w-full">
                          <InputTextarea id="missingDescriptionInputText" value={missingDetails} onChange={(e) => handleMissingDescriptionChange(e)}  className="w-full"/>
                        </div>
                    </div>
                    <Button icon="pi pi-flag" severity="warning" label="Report Missing" onClick={handleReportMissing} className="mt-2 mr-2"/>
                    <Button label="Cancel" onClick={() => setMissingDialogVisible(false)} className="mt-2"/>
                </Dialog>
              </div>
            </Fieldset>
          )}
          <Fieldset
            legend="Asset History"
            toggleable
            collapsed={historyCollapsed}
            onToggle={(event) => handleAssetHistoryToggle(event)}
          >
            {assetHistory && (
              <AssetHistoryPanel inventoryOperations={assetHistory} />
            )}
          </Fieldset>
        </div>
      </div>
    </div>
  );
};

export default AssetManagementPage;
