import { useState } from "react";

import { useArmoryApi } from "../hooks/useArmoryApi";
import { useArmory, useArmoryDispatch } from "../contexts/ArmoryContext";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";

const REPAIR_STATUS: number = 1;
const RETIREMENT_STATUS: number = 2;
const MISSING_STATUS: number = 3;

const statusTypeMap: { [key: number]: string } = {
  [REPAIR_STATUS]: "Repair",
  [RETIREMENT_STATUS]: "Retirement",
  [MISSING_STATUS]: "Missing",
};

const statusValueMap: { [key: number]: { [key: number]: string } } = {
  [REPAIR_STATUS]: {
    0: "Undamaged",
    15: "Damaged",
  },
  [RETIREMENT_STATUS]: {
    0: "In Circulation",
    17: "Retired",
    18: "Sold",
    19: "Donated",
    20: "Landfill",
  },
  [MISSING_STATUS]: {
    0: "Not Missing",
    16: "Lost",
    21: "Student MIA",
    25: "Stolen"
  },
};

const BulkSetStatusPanel: React.FC = () => {
  const { isCursorLoading } = useArmory();
  const dispatch = useArmoryDispatch();
  const {
    bulkSetRepair,
    bulkSetRetirement,
    bulkSetMissing,
    validateSerialNumbers,
  } = useArmoryApi();
  const [statusType, setStatusType] = useState<number | null>(null);
  const [statusToReport, setstatusToReport] = useState<number | null>(null);
  const [serials, setSerials] = useState<string>("");

  const statusFunctionMap: { [key: number]: Function } = {
    [REPAIR_STATUS]: bulkSetRepair,
    [RETIREMENT_STATUS]: bulkSetRetirement,
    [MISSING_STATUS]: bulkSetMissing,
  };

  const handleSubmit = async () => {
	dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});

    //convert serials to array
    const serialArr = serials.split("\n");

    //validate serials
    const serialResponse = await validateSerialNumbers(serialArr);
    if (serialResponse.statusCode === 200 && statusType !== null && statusToReport !== null) {
      //serials valid, set status
      const response = await statusFunctionMap[statusType](serialArr, statusToReport);

      if (response.statusCode === 200) {
        const successMsg: string = `Bulk Set ${statusTypeMap[statusType]} Status: ${statusValueMap[statusType][statusToReport]} succeeded.`;
		dispatch({type: "SHOW_TOAST", payload: {severity: "success", summary: "Success", detail: successMsg, life: 3000}});
	    dispatch({type: "ADD_LOG_ENTRY", payload: {message: `${successMsg}\r\nSerials: ${serialArr.join("\r\n")}`}});
        setSerials("");
        setstatusToReport(null);
        setStatusType(null);
      } else {
        const errorMsg: string = `Bulk Set ${statusTypeMap[statusType]} Status: ${statusValueMap[statusType][statusToReport]} failed. ${response.error}`;
        dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
	    dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
      }
    } else {
      const errorMsg: string = `One or more serial numbers failed to validate. ${serialResponse.error}`;
	  dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
	  dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
    }

    setTimeout(() => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);
  };

  //build statusTypeOptions using statusTypeMap
  const statusTypeOptions: SelectItem[] = Object.keys(statusTypeMap).map((key: string) => ({
    label: statusTypeMap[parseInt(key)],
    value: parseInt(key),
  }) as SelectItem);

  //build statusValueOptions using statusValueMap
  const statusValueOptions: { [key: number]: SelectItem[] } = Object.keys(statusValueMap).reduce((acc: { [key: number]: SelectItem[] }, key: string) => {
    acc[parseInt(key)] = Object.keys(statusValueMap[parseInt(key)]).map((innerKey: string) => ({
      label: statusValueMap[parseInt(key)][parseInt(innerKey)],
      value: parseInt(innerKey),
    }) as SelectItem);
    return acc;
  }, {} as { [key: number]: SelectItem[] });

  return (
    <div className="p-fluid">
      <div>
        <div>
          <label htmlFor="statusTypeDropdown">Status To Set</label>
          <Dropdown
            value={statusType}
            onChange={(e) => setStatusType(e.value)}
            disabled={isCursorLoading}
            options={statusTypeOptions}
            placeholder="Select..."
          />
        </div>
        <div>
          <label htmlFor="statusValueDropdown">Status Value</label>
          <Dropdown
            value={statusToReport}
            onChange={(e) => setstatusToReport(e.value)}
            disabled={statusType === null || isCursorLoading}
            options={statusValueOptions[statusType ?? 0]}
            placeholder="Select..."
          />
        </div>
      </div>
      <InputTextarea
        value={serials}
        onChange={(e) => setSerials(e.currentTarget.value)}
        disabled={statusType === null || statusToReport === null || isCursorLoading}
        placeholder="Enter or scan serials"
        autoResize={true}
        className="p-mb-3 p-inputtext-sm"
      />
      <Button
        label="Submit"
        onClick={handleSubmit}
        disabled={statusType === null || statusToReport === null || isCursorLoading}
        className="p-button-rounded p-button-success p-mt-2"
      />
    </div>
  );
};

export default BulkSetStatusPanel;
