import { useArmory } from "../contexts/ArmoryContext";
import ArmorySiteDropdown from "./ArmorySiteDropdown";
import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

const SiteSelectDialog: React.FC = () => {
    const { site } = useArmory();
    const [siteSelected, setSiteSelected] = useState(site != null);

    useEffect(() => {
        handleHide();
    }, [site]);

    const handleHide = () => {
        if (site != null) {
            setSiteSelected(true);
        }
    };

    return (
        <div>
            <Dialog
                header="Select a site to continue"
                closable={false}
                visible={!siteSelected}
                onHide={handleHide}
                id="siteSelectDialog"
                maximized
            >            
            <ArmorySiteDropdown />
            </Dialog>
        </div>
    );
}

export default SiteSelectDialog;