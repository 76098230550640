import { useState } from 'react';

import { useArmoryApi } from '../hooks/useArmoryApi';
import { useArmory, useArmoryDispatch } from '../contexts/ArmoryContext';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import 'primeicons/primeicons.css';
import { useNavigate } from 'react-router-dom';

interface AssetQuickSearchProps {
    assetSerial?: string;
}

const AssetQuickSearchPanel: React.FC<AssetQuickSearchProps> = ({ assetSerial = "" }) => {
  const { isCursorLoading } = useArmory();
  const dispatch = useArmoryDispatch();
  const { getInventoryObject } = useArmoryApi();

  const [serial, setSerial] = useState(assetSerial);

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerial(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});

    const response = await getInventoryObject(serial);

    if (response.statusCode === 200) {
        navigate(`/asset/${serial}`);
    } else {
        const errorMsg: string = `Asset: ${serial} - Not found. ${response.error}`
        dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
        dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
    }
    
    setTimeout(() => {
        dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);    
  };

  return (
    <div className=''>
      <label htmlFor='assetQuickSearchInputText'>Asset Serial Number</label>
      <div className="p-inputgroup">
        <InputText
          id='assetQuickSearchInputText'
          value={serial}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
        <Button
          icon="pi pi-search"
          className="p-button-success"
          onClick={handleSubmit}
          disabled={isCursorLoading}
        />
      </div>
    </div>
  );
}

export default AssetQuickSearchPanel;