import React, { useEffect, useState } from 'react';
import { useArmory } from '../contexts/ArmoryContext';
import { ScrollPanel } from 'primereact/scrollpanel';

type ActivityLogEntry = {
  timestamp: Date;
  message: string;
};

const ActivityLogComponent: React.FC = () => {
  const state = useArmory();
  const [currentLog, setCurrentLog] = useState<ActivityLogEntry[]>(state.log);

  useEffect(() => {
    const updatedLog: any[] = state.log
		.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
		.map((entry, index) => ({
			timestamp: entry.timestamp,
			message: entry.message
	}));

	setCurrentLog(updatedLog);
  }, [state.log]);

  return (
    <ScrollPanel style={{width: "100%", height: "100vh"}}>
      <ul>
        {currentLog && currentLog.length > 0 ? currentLog.map((entry, index) => (
          <li key={index}>
            {entry.timestamp.toLocaleTimeString()}: {entry.message}
          </li>
        )): "No logs found"}
      </ul>
    </ScrollPanel>
  );
};

export default ActivityLogComponent;
