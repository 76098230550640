import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const ProtectedRoute: React.FC = ({ children }: any) => {
    const { accessToken } = useAuth();
    if (!accessToken) {
        return <Navigate to="/login" />;
    }
    return <Outlet />;
};

export default ProtectedRoute;