import { useState } from "react";

import { useArmoryApi } from "../hooks/useArmoryApi";
import { useArmory, useArmoryDispatch } from "../contexts/ArmoryContext";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const BulkSetSiteOnlyPanel: React.FC = () => {
  const { isCursorLoading } = useArmory();
  const dispatch = useArmoryDispatch();
  const { bulkSetSiteUseOnly, validateSerialNumbers } = useArmoryApi();
  const [siteUseOnly, setSiteUseOnly] = useState<boolean | null>(null);
  const [serials, setSerials] = useState<string>("");

  const handleSerialChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSerials(e.target.value);
  };

  const handleSubmit = async () => {
    dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});

    //convert serials to array
    const serialArr = serials.split("\n");

    //validate serials
    const serialResponse = await validateSerialNumbers(serialArr);
    if (serialResponse.statusCode === 200 && siteUseOnly !== null) {
      //serials valid, assign to site
      const response = await bulkSetSiteUseOnly(serialArr, siteUseOnly);

      if (response.statusCode === 200) {
        const successMsg: string = `Bulk Set Site Use Only: ${siteUseOnly} succeeded.`;
		dispatch({type: "SHOW_TOAST", payload: {severity: "success", summary: "Success", detail: successMsg, life: 3000}});
	    dispatch({type: "ADD_LOG_ENTRY", payload: {message: `${successMsg}\r\nSerials: ${serialArr.join("\r\n")}`}});
        setSerials("");
        setSiteUseOnly(null);
      } else {
        const errorMsg: string = `Bulk Set Site Use Only: ${siteUseOnly} failed. ${response.error}`;
        dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
	    dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
      }
    } else {
      const errorMsg: string = `One or more serial numbers failed to validate. ${serialResponse.error}`;
      dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
	  dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
    }

    setTimeout(() => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);
  };

  return (
    <div className="p-fluid">
      <label htmlFor="siteUseOnlyDropdown">Site Use Only?</label>
      <Dropdown
        value={siteUseOnly}
        onChange={(e) => setSiteUseOnly(e.value)}
        disabled={isCursorLoading}
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        placeholder="Select..."
      />
      <InputTextarea
        value={serials}
        onChange={handleSerialChange}
        disabled={siteUseOnly === null || isCursorLoading}
        placeholder="Enter or scan serials"
        autoResize={true}
        className="p-mb-3 p-inputtext-sm"
      />
      <Button
        label="Submit"
        onClick={handleSubmit}
        disabled={siteUseOnly === null || isCursorLoading}
        className="p-button-rounded p-button-success p-mt-2"
      />
    </div>
  );
};

export default BulkSetSiteOnlyPanel;
