import { Timeline } from "primereact/timeline";
import { InventoryOperation } from "../apis/armoryApi";

interface AssetHistoryPanelProps {
    inventoryOperations: InventoryOperation[];
}

interface InventoryActions {
    [key: number]: string;
}

const AssetHistoryPanel: React.FC<AssetHistoryPanelProps> = ({ inventoryOperations }) => {
    const inventoryActions: InventoryActions = {
        2: "Checked In",
        3: "Checked Out"
    };    
    const assetHistory = inventoryOperations.filter(operation => operation.success && (operation.actionId in inventoryActions)).reverse();

    return (
        <Timeline
            value={assetHistory}
            opposite={(item) => inventoryActions[item.actionId]}
            content={(item) => <small className="text-color-secondary">{item.date.toLocaleString()}</small> }
        />
    );
};

export default AssetHistoryPanel;