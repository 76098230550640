import { useEffect, useState } from "react";
import { useArmoryApi } from "../hooks/useArmoryApi";
import { SiteMetrics } from "../apis/armoryApi";
import { TabPanel, TabView } from "primereact/tabview";

interface MetricsPanelProps {
    siteId: number,
    showSite?: boolean,
    showSchoolYear?: boolean,
    showTotal?: boolean,
    showAvailable?: boolean,
    showSiteUseOnly?: boolean,
    showNeedsRepair?: boolean,
    showRetired?: boolean,
    showMissing?: boolean,
    showInTransit?: boolean,
    showCheckedOut?: boolean,
    showReportTime?: boolean
}

const MetricsPanel: React.FC<MetricsPanelProps> = ({
    siteId,
    showSite,
    showSchoolYear,
    showTotal,
    showAvailable,
    showSiteUseOnly,
    showNeedsRepair,
    showRetired,
    showMissing,
    showInTransit,
    showCheckedOut,
    showReportTime
}) => {
    const { getSiteMetrics } = useArmoryApi()

    const [siteMetricsRecord, setSiteMetricsRecord] = useState<SiteMetrics[]>();
    const [activeIndex, setActiveIndex] = useState(1);

    useEffect(() => {
        const getMetrics = async () => {
            const apiResponse = await getSiteMetrics(siteId);

            if (apiResponse.data) {
                setSiteMetricsRecord(apiResponse.data)
            }
        }

        getMetrics();
    }, [siteId]);

    return siteMetricsRecord ? (
        <div>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {Object.entries(siteMetricsRecord).map(([key, value]) => (
                    <TabPanel header={value.type}>
                        <ul>
                            {showSite && <li>Site: {value.siteId}</li>}
                            {showSchoolYear && <li>School Year: {value.schoolYear}</li>}
                            {showTotal && <li>Total: {value.total}</li>}
                            {showAvailable && <li>Available: {value.available} ({parseFloat((100 * value.available / value.total).toFixed(1))}%)</li>}
                            {showSiteUseOnly && <li>Site Use Only: {value.siteUseOnly} ({parseFloat((100 * value.siteUseOnly / value.total).toFixed(1))}%)</li>}
                            {showNeedsRepair && <li>Needs Repair: {value.needsRepair} ({parseFloat((100 * value.needsRepair / value.total).toFixed(1))}%)</li>}
                            {showMissing && <li>Missing: {value.missing} ({parseFloat((100 * value.missing / value.total).toFixed(1))}%)</li>}
                            {showRetired && <li>Retired: {value.retired} ({parseFloat((100 * value.retired / value.total).toFixed(1))}%)</li>}
                            {showInTransit && <li>In Transit: {value.inTransit} ({parseFloat((100 * value.inTransit / value.total).toFixed(1))}%)</li>}
                            {showCheckedOut && <li>Checked Out: {value.checkedOut} ({parseFloat((100 * value.checkedOut / value.total).toFixed(1))}%)</li>}
                            {showReportTime && <li>Report Time: {value.reportTime.toString()}</li>}
                        </ul>
                    </TabPanel>
                ))}
            </TabView>
        </div>
    ) : (
        <div>
            <p>No assets assigned to this site.</p>
        </div>
    );
};

export default MetricsPanel;