import { useAuthHelper } from "../hooks/useAuthHelper";

import { Button } from 'primereact/button';
import { FaMicrosoft } from "react-icons/fa";

import gLogoWhite from "../images/G Color Words White Outline.svg"
import gLogoDark from "../images/G Color Words Dark Outline.svg"

import backgroundImage from "../images/snowymountain.jpg";
import { useContext } from "react";
import { PrimeReactContext } from "primereact/api";
import useTheme from "../hooks/useTheme";

const LoginPage: React.FC = () => {
    const { changeTheme } = useContext(PrimeReactContext);
    const { currentTheme, DARK_THEME } = useTheme(changeTheme);
    const { login } = useAuthHelper();

    return (
        <div className="flex h-screen">
            {/* Login Card Section */}
            <div className="flex align-items-center w-4" style={{ zIndex: '1000'}}>
                <div className="w-full">
                    <div className="text-center mb-5">
                        <img src={currentTheme === DARK_THEME ? gLogoWhite : gLogoDark} alt="hyper" height={70} className="mb-3" />
                        <div className="text-900 text-3xl font-medium mb-3">GOAL Armory</div>
                    </div>
                    <div className='flex justify-content-center'>
                        <Button onClick={login}>
                            <FaMicrosoft size="2rem" className="mr-2" /> Login with Microsoft
                        </Button>
                    </div>
                </div>
            </div>

            {/* Image Section */}
            <div
                className="w-full"
                style={{
                    backgroundImage: `url(${backgroundImage}), linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2))`,
                    backgroundBlendMode: 'overlay',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right center'
                }}
            >
            </div>
        </div>
    );
}

export default LoginPage;