import { useEffect } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";

import { useAuth } from "./contexts/AuthContext";
import { useArmory } from "./contexts/ArmoryContext";

// Context
import Layout from "./components/layout/Layout"
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import AssetManagementPage from "./pages/AssetManagementPage";
import CheckOutPage from "./pages/CheckOutPage";
import CheckInPage from "./pages/CheckInPage";
import AdminPage from "./pages/AdminPage";
import InventoryManagementPage from "./pages/InventoryManagementPage";

const App: React.FC = () => {
  const navigate = useNavigate();
  const { onAuthenticated } = useAuth();
  const { userGid } = useArmory();

  useEffect(() => {
    onAuthenticated(() => navigate("/"));
  }, []);

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route path="/me" element={<ProfilePage userId={userGid}/>} />
          <Route path="/" element={<HomePage />} />
          <Route path="/asset/:assetSerial" element={<AssetManagementPage />} />
          <Route path="/check-out" element={<CheckOutPage />} />
          <Route path="/check-in" element={<CheckInPage />} />
          <Route path="/admin" element={<AdminPage userId={userGid}/>} />
          <Route path="/stock" element={<InventoryManagementPage/>} />
        </Route>
      </Route>
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  )
};

export default App;
