import { useState } from "react";

const linkElementId = "theme-link";

type ChangeThemeFunction = (
  oldTheme?: string,
  newTheme?: string,
  linkElementId?: string,
  callback?: () => void
) => void;

const useTheme = (changeTheme?: ChangeThemeFunction) => {
  const DARK_THEME = "/themes/soho-dark/theme.css";
  const LIGHT_THEME = "/themes/soho-light/theme.css";

  // Retrieve the initial theme from browser settings or session storage
  const getInitialTheme = () => {
      const windowTheme = window.matchMedia("(prefers-color-scheme: light)").matches ? LIGHT_THEME : DARK_THEME;
      const storedTheme = localStorage.getItem("theme");

      if (storedTheme) {
          return storedTheme;
      }

      localStorage.setItem("theme", windowTheme);

      return windowTheme;
  };

  // Internal state to manage the currently active theme
  const [currentTheme, setCurrentTheme] = useState<string>(getInitialTheme());

  const toggleTheme = () => {
    if (currentTheme === DARK_THEME) {
      setLightTheme();
    } else {
      setDarkTheme();
    }
  };

  const setLightTheme = () => {
    if (changeTheme) {
      changeTheme(DARK_THEME, LIGHT_THEME, linkElementId, () => {
        setCurrentTheme(LIGHT_THEME);
        localStorage.setItem("theme", LIGHT_THEME);
      });
    }
  };

  const setDarkTheme = () => {
    if (changeTheme) {
      changeTheme(LIGHT_THEME, DARK_THEME, linkElementId, () => {
        setCurrentTheme(DARK_THEME);
        localStorage.setItem("theme", DARK_THEME);
      });
    }
  };

  return { currentTheme, toggleTheme, DARK_THEME, LIGHT_THEME };
};

export default useTheme;
