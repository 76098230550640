import React, { useEffect, useState } from "react";

import Ribbon from "./Ribbon";
import Footer from "./Footer";
import ActivityLog from "../ActivityLog";

import { useArmory, useArmoryDispatch } from "../../contexts/ArmoryContext";

import { Outlet } from "react-router-dom";

import { Sidebar } from 'primereact/sidebar';
import { Fieldset } from "primereact/fieldset";
import { Toast } from "primereact/toast";

const Layout: React.FC = () => {

    const [showDrawer, setShowDrawer] = useState(false);
    const handleToggleDrawer = () => setShowDrawer(!showDrawer);

    const { isCursorLoading, toast } = useArmory();
	const dispatch = useArmoryDispatch();

    useEffect(() => {
		dispatch({type: "ADD_LOG_ENTRY", payload: {message: "App loaded"}});
    }, []); // The empty array means this effect runs only once on mount
    // unless you are in dev mode, then it runs twice for reasons

    useEffect(() => {
        document.body.style.cursor = isCursorLoading ? "wait" : "default";
    }, [isCursorLoading]);

    return (
        <div className="flex flex-column">

            <div className="header" style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}>
                <Ribbon />
            </div>

            {/* Body content */}
            <div className="flex flex-row">
                <div className="flex-grow-1" style={{ paddingBottom: '70px', paddingTop: '50px' }}>
                    <Toast ref={toast} />
                    <Outlet />
                </div>
                <div className="ml-auto m-2 mt-6 w-2 hidden xl:block">
                    <Fieldset legend="Activity Log" className="mt-4">
                            <ActivityLog />
                    </Fieldset>
                </div>
            </div>


            {/* Slide Away Right Drawer (placeholder via Modal) */}
            <Sidebar visible={showDrawer} position="right" onHide={() => handleToggleDrawer()}>
                <ActivityLog />
            </Sidebar>

            {/* Sticky Footer */}
            <div className="footer" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}>
                <Footer 
                    onShowLog={() => handleToggleDrawer()}
                />
            </div>
        </div>
    );
}

export default Layout;
