import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useArmoryApi } from "../hooks/useArmoryApi";
import { Site } from '../apis/armoryApi';

interface SiteDropdownProps {
    handleChange: (site: Site | null) => void;
    disabled?: boolean;
    selectedSiteName?: string;
}

const SiteDropdown: React.FC<SiteDropdownProps> = ({ handleChange, disabled = false, selectedSiteName=null }) => {
    const { getSites } = useArmoryApi();
    const [siteList, setSiteList] = useState<Site[]>();
    const [siteListByRegion, setSiteListByRegion] = useState<{ [key: string]: Site[] }>({});
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);

    useEffect(() => {
        const getSiteListAsync = async() => {
            var result = await getSites();

            if (result.data) {
                setSiteList(result.data);
            }
        }

        getSiteListAsync();
    }, []);

    useEffect(() => {
        if (selectedSiteName === "" && selectedSite) {
            setSelectedSite(null);
            handleChange(null);
        } else if (selectedSiteName) {
            const site = siteList?.find(s => s.name === selectedSiteName);
            if (site && site !== selectedSite) {
                setSelectedSite(site);
                handleChange(site);
            }
        }
    }, [selectedSiteName, siteList, handleChange, selectedSite]);
    
    

    useEffect(() => {
        const regionGroup: { [key: string]: Site[] } = {};

        if (siteList) {
            siteList.forEach(site => {
                const key = site.region?.name;

                if (key) {
                    if (regionGroup[key]) {
                        regionGroup[key].push(site);
                    }
                    else {
                        regionGroup[key] = [site];
                    }
                }
            });
        }

        setSiteListByRegion(regionGroup);
    }, [siteList]);
    
    const handleDropdownChange = (option: any) => {
        const newSite = siteList?.find(site => site.name === option);

        if (newSite) {
            setSelectedSite(newSite);
            handleChange(newSite);
        }
    };

    const dropdownOptions = Object.keys(siteListByRegion).sort().map(key => ({
        label: key,
        items: siteListByRegion[key].sort((a, b) => a.name.localeCompare(b.name)).map(site => ({ label: site.name, value: site.name }))
    }));

    const optionGroupTemplate = (option: any) => {
        return (
            <div className="flex align-items-center" style={{ fontSize: "16px" }}>
                <span className='pi pi-building-columns' style={{ paddingInline: "10px" }}></span>
                <div>{option.label}</div>
            </div>
        );
    };

    return (
        <Dropdown
            filter
            filterInputAutoFocus
            filterPlaceholder="Search"
            disabled={disabled}
            options={dropdownOptions}
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionGroupTemplate={optionGroupTemplate}
            optionLabel="label"
            placeholder={"Select a Site"}
            value={selectedSite?.name}
            onChange={(e) => handleDropdownChange(e.value)}
            title='Site Select Dropdown'
        />
    );
}

export default SiteDropdown;