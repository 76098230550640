import { useContext, useState } from "react";

import useTheme from "../hooks/useTheme";

import { PrimeReactContext } from "primereact/api";

import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";

const ThemeToggle = () => {
  const { changeTheme } = useContext(PrimeReactContext);
  const { currentTheme, toggleTheme, DARK_THEME } = useTheme(changeTheme);

  const [checked, setChecked] = useState<boolean>(currentTheme === DARK_THEME);

  return (
    <div className="flex align-items-center gap-2" style={{ paddingLeft: '1.25rem' }}>
      <span className="pi pi-sun"></span>
      <InputSwitch
        checked={checked}
        onChange={(e: InputSwitchChangeEvent) => {
          //console.log("TOGGLE TOGGLED - CURRENT ", currentTheme);
          toggleTheme();
          setChecked(!checked);
              }}
        title={checked ? "Switch to Light Theme" : "Switch to Dark Theme"}
      />
          <Tooltip target=".p-inputswitch" content={checked ? "Switch to Light Theme" : "Switch to Dark Theme"} />
      <span className="pi pi-moon"></span>
    </div>
  );
};

export default ThemeToggle;
