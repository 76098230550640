import { useState } from 'react';

import { useArmoryApi } from '../hooks/useArmoryApi';
import { useArmory, useArmoryDispatch } from '../contexts/ArmoryContext';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import 'primeicons/primeicons.css';

function CheckInPanel({ isDisabled = false, assetSerial = "", onSuccess = () => { } }: { isDisabled?: boolean, assetSerial?: string, onSuccess?: () => void}) {
  const { site, isCursorLoading } = useArmory();
  const dispatch = useArmoryDispatch();
  const { checkIn } = useArmoryApi();


  const [serial, setSerial] = useState(assetSerial);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerial(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});

    const response = await checkIn(serial, site?.id ?? 0);

    if (response.statusCode === 200) {
        const successMsg: string = `Asset: ${serial} - Check in succeeded.`;
		dispatch({type: "SHOW_TOAST", payload: {severity: "success", summary: "Success", detail: successMsg, life: 3000}});
		dispatch({type: "ADD_LOG_ENTRY", payload: {message: successMsg}});
        onSuccess();
    } else {
        const errorMsg: string = `Asset: ${serial} - Check in failed. ${response.error}`;
		dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", detail: errorMsg, life: 3000}});
		dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
    }

    if (!isDisabled) {
        setSerial("");
    }
    
    setTimeout(() => {
        dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);    
  };

  return (
    <div className=''>
      <label htmlFor='checkInAssetInputText'>Asset Serial Number</label>
      <div className="p-inputgroup">
        <InputText
          id='checkInAssetInputText'
          value={serial}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          disabled={isDisabled}
        />
        <Button
          icon="pi pi-check"
          className="p-button-success"
          onClick={handleSubmit}
          disabled={isCursorLoading}
          title='Check In Asset'
        />
      </div>
    </div>
  );
}

export default CheckInPanel;