import { Fieldset } from "primereact/fieldset";
import SendAssetsPanel from "../components/SendAssetsPanel";
import ReceiveAssetsPanel from "../components/ReceiveAssetsPanel";

const InventoryManagementPage: React.FC = () => {
  return (
    <div className="mt-5 flex justify-content-center gap-3">
      <Fieldset legend="Send Assets">
        <SendAssetsPanel />
      </Fieldset>
      <Fieldset legend="Receive Assets">
        <ReceiveAssetsPanel />
      </Fieldset>
    </div>
  );
};

export default InventoryManagementPage;
