import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';

import {
    PublicClientApplication,
    AuthenticationResult,
    Configuration,
    AccountInfo,
    SilentRequest,
} from '@azure/msal-browser';

// Define your MSAL configuration here
const msalConfig: Configuration = {
    auth: {
        clientId: 'f412d27d-17dc-4dc6-888c-aba967cc4395', // Your client ID
        authority: 'https://login.microsoftonline.com/f5e7fc53-90d9-42c9-8340-61e0c785af0f/v2.0', // Your authority
        redirectUri: `${process.env.REACT_APP_AUTH_REDIRECT_URL}`, // Your redirect URI,
        postLogoutRedirectUri: `${process.env.REACT_APP_AUTH_REDIRECT_URL}/login`
    },
};

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

interface AuthContextType {
    account: AccountInfo | null;
    error: string | null;
    login: () => Promise<boolean>;
    logout: () => void;
    acquireToken: () => Promise<string | null>;
    accessToken: string | null; // Your custom access token,
    onAuthenticated: any;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [account, setAccount] = useState<AccountInfo | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [accessToken, setAccessToken] = useState<string | null>(null);

    const onAuthenticated = async (callback: any) => {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            setAccount(accounts[0]);
            try {
                const accessToken = await exchangeToken(accounts[0]?.idToken ?? "");
                if (accessToken) {
                    setAccessToken(accessToken);
                    callback();
                } else {
                    console.error('No access token returned');
                }
            } catch (error) {
                console.error('Error fetching access token:', error)
            }
        }
    }

    useEffect(() => {
        onAuthenticated(() => {});
    }, []);

    const handleLoginResponse = async (response: AuthenticationResult | null): Promise<boolean> => {
        if (response?.account && response?.idToken) {
            setAccount(response.account);
            const accessToken = await exchangeToken(response.idToken);
            setAccessToken(accessToken); // Store your custom access token
            return true; // Indicates successful login
        }
        return false; // Indicates failed login
    };

    const login = async (): Promise<boolean> => {
        try {
            const response = await msalInstance.loginPopup();
            return handleLoginResponse(response);
        } catch (error: any) {
            setError(error);
            return false; // Login failed
        }
    };

    const logout = async () => {
        if (!account) return;
        setAccount(null);
        setAccessToken(null);
        await msalInstance.logoutPopup({ account });
    };

    const acquireToken = async (): Promise<string | null> => {
        if (!account) return null;
        const request: SilentRequest = {
            account,
            scopes: ["user.read"], // Update scopes according to your requirement
        };

        try {
            const response = await msalInstance.acquireTokenSilent(request);
            const idToken = response.idToken; // Get the ID token
            // Exchange ID token for your own access token
            const customToken = await exchangeToken(idToken);
            setAccessToken(customToken); // Store your custom access token
            return customToken;
        } catch (error) {
            console.error('Acquire token silently failed, falling back to popup', error);
            try {
                const response = await msalInstance.acquireTokenPopup(request);
                const idToken = response.idToken; // Get the ID token
                // Exchange ID token for your own access token
                const customToken = await exchangeToken(idToken);
                setAccessToken(customToken); // Store your custom access token
                return customToken;
            } catch (popupError) {
                setError('Acquire token with popup failed');
                console.error(popupError);
                return null;
            }
        }
    };

    // Custom token exchange function
    const exchangeToken = async (idToken: string): Promise<string | null> => {
        try {
            //const url = 'https://localhost:44394/AuthenticateWithIDToken';
            const url = 'https://goal-app-ident-prod-01.azurewebsites.net/AuthenticateWithIDToken'
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${idToken}`
                },
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.text();
            return data;
        } catch (error) {
            setError('Token exchange failed');
            console.error(error);
            return null;
        }
    };

    return (
        <AuthContext.Provider value={{ account, error, login, logout, acquireToken, accessToken, onAuthenticated  }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};