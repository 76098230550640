export interface LogEntry {
    id?: number,
    timestamp: Date,
    message: string
}

const dbName = "logDb";
const dbVersion = 1;
const storeName = "logs";

const openDatabase = async(): Promise<IDBDatabase> => {
    const databasePromise: Promise<IDBDatabase> = new Promise((resolve: any, reject: any) => {
        const request = indexedDB.open(dbName, dbVersion);

        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);
        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            const db = (event.target as IDBOpenDBRequest).result;
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName, {keyPath: "id", autoIncrement: true});
            }
        };
    });

    return databasePromise;
};

export const writeLog = async(timestamp: Date, message: string): Promise<void> => {
    const db = await openDatabase();

    const writeLogPromise: Promise<void> = new Promise((resolve: any, reject: any) => {
        const transaction = db.transaction(storeName, "readwrite");
        const store = transaction.objectStore(storeName);
        const logEntry: LogEntry = { timestamp, message };
        const request = store.add(logEntry);

        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve();
    });

    return writeLogPromise;
};

export const readLog = async(getTodaysLogs: boolean = false): Promise<LogEntry[]> => {
    const db = await openDatabase();

    const readLogPromise: Promise<LogEntry[]> = new Promise((resolve: any, reject: any) => {
        const transaction = db.transaction(storeName, "readonly");
        const store = transaction.objectStore(storeName);
        const request = store.getAll();

        request.onerror = () => reject(request.error);
        request.onsuccess = () => {
            if (!getTodaysLogs) {
                resolve(request.result as LogEntry[])
            }
            else {
                const allLogs: LogEntry[] = request.result as LogEntry[]
                const today = new Date();
                const currentDate = today.toISOString().split("T")[0];

                const todaysLogs = allLogs.filter((log) => {
                    const logDate = new Date(log.timestamp).toISOString().split("T")[0];
                    return logDate === currentDate;
                });

                resolve(todaysLogs);
            }
        };        
    });

    return readLogPromise;
};