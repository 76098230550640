import { useEffect, useState } from "react";

import { InventoryObjectTableEntry } from "../apis/armoryApi";

import { useArmoryApi } from "../hooks/useArmoryApi";
import { useArmory } from "../contexts/ArmoryContext";

import InventoryObjectDataTable from "../components/tables/InventoryObjectDataTable";
import MetricsPanel from "../components/MetricsPanel";
import CheckInPanel from "../components/CheckInPanel";
import CheckOutPanel from "../components/CheckOutPanel";

import { Fieldset } from "primereact/fieldset";
import SiteSelectDialog from "../components/SiteSelectDialog";
import AssetQuickSearchPanel from "../components/AssetQuickSearchPanel";
import { TabPanel, TabView } from "primereact/tabview";

const HomePage: React.FC = () => {
  const { site } = useArmory();
  const { getInventoryObjectsBySiteId } = useArmoryApi();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inventoryObjects, setInventoryObjects] = useState<
    InventoryObjectTableEntry[]
  >([]);

  const getInventoryObjectsData = async () => {
    const apiResponse = await getInventoryObjectsBySiteId(site?.id ?? 0, true);

    if (apiResponse.data) {
      setInventoryObjects(apiResponse.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  };

  useEffect(() => {
    getInventoryObjectsData();
  }, [site]);

  const onCheckInOutSuccess = () => {
    getInventoryObjectsData();
  };

  const getSchoolYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // JavaScript months are 0-indexed

    let schoolYearStart;
    let schoolYearEnd;

    if (currentMonth >= 7) {
      schoolYearStart = currentYear;
      schoolYearEnd = currentYear + 1;
    } else {
      schoolYearStart = currentYear - 1;
      schoolYearEnd = currentYear;
    }

    return `${schoolYearStart}-${schoolYearEnd}`;
  };

  return (
    <div>
      <SiteSelectDialog />
      <div className="m-4 mb-2">
        <div className="flex flex-column md:flex-row gap-2 mb-2">
          <div className="flex-grow-1">
            <Fieldset legend="Quick Actions">
              <TabView>
                <TabPanel header="Check In Asset">
                  <CheckInPanel onSuccess={onCheckInOutSuccess} />
                </TabPanel>
                <TabPanel header="Check Out Asset">
                  <CheckOutPanel onSuccess={onCheckInOutSuccess} />
                </TabPanel>
                <TabPanel header="Find Asset">
                  <AssetQuickSearchPanel />
                </TabPanel>
              </TabView>
            </Fieldset>
          </div>
          <div className="flex-grow-1">
            <Fieldset legend={getSchoolYear() + " " + site?.name + " Metrics"}>
              <MetricsPanel
                siteId={site?.id ?? 0}
                showSite={false}
                showSchoolYear={false}
                showTotal={true}
                showAvailable={true}
                showSiteUseOnly={true}
                showNeedsRepair={true}
                showRetired={true}
                showMissing={true}
                showInTransit={true}
                showCheckedOut={true}
                showReportTime={false}
              />
            </Fieldset>
          </div>
        </div>
        <div>
          <Fieldset legend={"Inventory at " + site?.name}>
            <InventoryObjectDataTable
              isLoading={isLoading}
              inventoryObjectsData={inventoryObjects}
              onRefreshButtonClick={getInventoryObjectsData}
            />
          </Fieldset>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
