import React, {
  createContext,
  useContext,
  useEffect,
  ReactNode,
  useRef,
  Ref,
  useReducer,
  Dispatch,
} from "react";

import { useArmoryApi } from "../hooks/useArmoryApi";
import { useAuth } from "./AuthContext";
import { UserPermissions } from "../apis/armoryApi";
import { Toast } from "primereact/toast";
import { readLog, writeLog } from "../services/logDatabase";
import { DataTableFilterMeta, SortOrder } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";

type Site = {
  name: string;
  id: number;
  region: Region | null;
};

type Region = {
  name: string;
  id: number;
};

type ActivityLogEntry = {
  timestamp: Date;
  message: string;
};

interface ArmoryContextType {
  site: Site | null;
  isOnline: boolean;
  lastCacheUpdateTime: Date;
  log: ActivityLogEntry[];
  isCursorLoading: boolean;
  userGid: number;
  userPerms: UserPermissions;
  toast: Ref<Toast>;
  sort: {
	sortField: string,
	sortOrder: SortOrder
  },
  filters: DataTableFilterMeta,
  inventoryTablePage: number;
}

const ArmoryContext = createContext<ArmoryContextType | undefined>(undefined);
const ArmoryDispatchContext = createContext<Dispatch<any> | undefined>(undefined);

// Simple runtime check for Electron
const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("electron");
};

// Provider component
export const ArmoryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const site: Site | null = null;
  const isOnline: boolean = navigator.onLine;
  const lastCacheUpdateTime: Date = new Date();
  const log: ActivityLogEntry[] = [];
  const isCursorLoading: boolean = false;
  const userGid: number = 0;
  const userPerms: UserPermissions = {
    canCheckOut: false,
    canCheckIn: false,
    canChangeStatus: false,
    isAdmin: false,
    isActive: false
  };
  const sort = {
	sortField: "",
	sortOrder: 0
  };
  const filters = {
	global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    skuName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    serial: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lesseeName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
  };
  const inventoryTablePage: number = 0;

  const { account } = useAuth();
  const { accessToken, getUserByEmail, getStatus, getUserPermissions } = useArmoryApi();

  //set user by getting account.username from AuthContext and sending it to useArmoryApi.getUserByEmail
  const fetchUser = async () => {
    if (account && accessToken) {
      const response = await getUserByEmail(account.username);
      if (response?.data?.id) {
        dispatch({type: "SET_VALUE", payload: {key: "userGid", value: response.data.id}});
        dispatch({type: "SET_VALUE", payload: {key: "site", value: response.data.homeSite}});
      }

      const permsResponse = await getUserPermissions(userGid, account.username);
        if (permsResponse?.data) {
          dispatch({type: "SET_VALUE", payload: {key: "userPerms", value: {
              canCheckOut: permsResponse.data.canCheckOut,
              canCheckIn: permsResponse.data.canCheckIn,
              canChangeStatus: permsResponse.data.canChangeStatus,
              isAdmin: permsResponse.data.isAdmin,
              isActive: permsResponse.data.isActive
          }}});
      }
    }
    };

  useEffect(() => {
    const fetchLogs = async () => {
      let log = await readLog(true);

      const logEntries: ActivityLogEntry[] = log.map((entry: any) => ({
        timestamp: new Date(entry.timestamp),
        message: entry.message,
      }));

      dispatch({type: "SET_VALUE", payload: {key: "log", value: logEntries}});
    };

    fetchLogs();
  }, []);

  useEffect(() => {
    fetchUser();
  }, [account, accessToken]);

  useEffect(() => {
    let intervalId : NodeJS.Timer;

    // Function to check the online status by making a HEAD request to the API
    const checkOnlineStatus = async () => {
        try {
            const response = await getStatus();
            if (response && response.statusCode && response.statusCode >= 200 && response.statusCode < 300) {
                dispatch({type: "SET_VALUE", payload: {key: "isOnline", value: true}}); // Set online status to true if the response is OK
                if (intervalId) {
                    clearInterval(intervalId); // Clear the interval if it exists
                }
            } else {
                dispatch({type: "SET_VALUE", payload: {key: "isOnline", value: false}}); // Set online status to false if the response is not OK
            }
        } catch (error) {
            dispatch({type: "SET_VALUE", payload: {key: "isOnline", value: false}}); // Set online status to false if there's an error
        }
    };

    // Handler for the 'online' event
    const handleOnline = () => {
        dispatch({type: "SET_VALUE", payload: {key: "isOnline", value: false}}); // Temporarily set online status to false until the URL is checked
        intervalId = setInterval(checkOnlineStatus, 5000); // Check the URL every 5 seconds
        checkOnlineStatus(); // Immediately check the URL once
    };

    // Handler for the 'offline' event
    const handleOffline = () => {
        dispatch({type: "SET_VALUE", payload: {key: "isOnline", value: false}}); // Set online status to false
        if (intervalId) {
            clearInterval(intervalId); // Clear the interval if it exists
        }
    };

    // Add event listeners for online and offline events
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Check the initial online status when the component mounts
    if (navigator.onLine) {
        handleOnline(); // If the browser is online, handle the online event
    }

    // Cleanup function to remove event listeners and clear the interval
    return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
        if (intervalId) {
            clearInterval(intervalId);
        }
    };
}, []);

  const toast = useRef<Toast>(null);

  const armoryReducer = (state: any, action: any) => {
    switch (action.type) {
      case "ADD_VALUE": {
          if (state[action.payload.key] === undefined) {
              return { ...state, [action.payload.key]: action.payload.value };
          }
          return state;
      }
      case "SET_VALUE": {
          return { ...state, [action.payload.key]: action.payload.value };
      }
      case "REMOVE_VALUE": {
          const newState = { ...state };
          delete newState[action.payload.key];
          return newState;
      }
      case "ADD_LOG_ENTRY": {
          const logMessage: string = action.payload.message;
          const newEntry: ActivityLogEntry = { timestamp: new Date(), message: logMessage };

		  const entryExists = state.log.some((entry: ActivityLogEntry) => entry.message === logMessage && entry.timestamp.getTime() === newEntry.timestamp.getTime());

		  if (!entryExists) { 
			writeLog(newEntry.timestamp, newEntry.message);

			return {
		      ...state,
			  log: [
				...state.log,
				newEntry
			  ]
			};
		  }

		  return state;
      }
      case "SHOW_TOAST": {
          return {
            ...state,
            toast: {
				...state.toast,
                severity: action.payload.severity,
                summary: action.payload.summary,
                detail: action.payload.detail,
                life: action.payload.life
            }
          };
      }
	  case "SET_SORT": {
		  return {
			  ...state,
			  sort: {
				  sortField: action.payload.sortField,
				  sortOrder: action.payload.sortOrder
			  }
		  };
	  }
	  case "SET_FILTERS": {
		  return {
			  ...state,
			  filters: {
				  ...state.filters,
				  ...action.payload
			  }
		  };
	  }
	  case "CLEAR_FILTERS": {
		  return {
			  ...state,
			  filters: {}
		  };
	  }
      default: {
          return state;
      }         
    }
  };

  const initialState = {
      site,
      isOnline,
      lastCacheUpdateTime,
      log,
      isCursorLoading,
      userGid,
      userPerms,
      toast,
	  sort,
	  inventoryTablePage,
	  filters
  };

  const [state, dispatch] = useReducer(
      armoryReducer,
      initialState
  );

  useEffect(() => {
    if (state.toast && state.toast.current) {
      state.toast.current.show({
          severity: state.toast.severity,
          summary: state.toast.summary,
          detail: state.toast.detail,
          life: state.toast.life
      });
    }
  }, [state.toast]);

  return (
    <ArmoryContext.Provider value={state}>
        <ArmoryDispatchContext.Provider value={dispatch}>
            {children}
        </ArmoryDispatchContext.Provider>
    </ArmoryContext.Provider>
  );
};

// Hook for easier consumption of the context
export const useArmory = () => {
  const context = useContext(ArmoryContext);
  if (context === undefined) {
    throw new Error("useArmory must be used within a ArmoryProvider");
  }
  return context;
};

export const useArmoryDispatch = () => {
    const context = useContext(ArmoryDispatchContext);
    if (context === undefined) {
        throw new Error("useArmoryDispatch must be used within a ArmoryDispatchProvider");
    }
    return context;
}
