import React, { useState, useEffect, CSSProperties } from "react";
import InventoryObjectDataTable from "./tables/InventoryObjectDataTable";
import {
  User,
  Staff,
  isStaff,
  InventorySearchModel,
  InventoryObjectTableEntry,
  getStudentImage,
} from "../apis/armoryApi";
import { useArmoryApi } from "../hooks/useArmoryApi";

import { Fieldset } from "primereact/fieldset";
import { Badge } from "primereact/badge";

type UserProfileComponentProps = {
  userId: number;
};

const UserProfileComponent: React.FC<UserProfileComponentProps> = ({
  userId,
}) => {
  const { getUserById, searchAssets, getStudentImage } = useArmoryApi();
  const [user, setUser] = useState<User | null>(null);
  const [base64String, setBase64String] = useState("");
  const [assets, setAssets] = useState<InventoryObjectTableEntry[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchUserAndAssets = async () => {
    try {
      const response = await getUserById(userId);
      if (response.data === null) {
        setError("User not found");
      }
      setUser(response.data);
    } catch (error) {
      setError("Failed to fetch user data");
    }

    const searchModel: InventorySearchModel = {
      RepairStatusId: null,
      RetirementStatusId: null,
      TransitSiteId: null,
      AssetSkuId: null,
      SiteSchoolYear: null,
      LesseeId: userId,
    };

    const assetsResponse = await searchAssets(searchModel);
    setAssets(assetsResponse.data ?? []);
  };

  const fetchImage = async () => {
    try {
      const response = await getStudentImage(userId);
      setBase64String(response.data ?? "");
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    fetchUserAndAssets();
    fetchImage();
  }, [userId]);

  if (error) {
    return <h1>{error}</h1>;
  }

  const imageStyles: CSSProperties = {
    display: "block",
    margin: "0 auto", // Center the image horizontally
    borderRadius: "50%", // Rounded edges
    width: "150px", // Adjust size as needed
    height: "150px", // Adjust size as needed
    objectFit: "cover", // Ensure the image covers the entire area and is centered
  };

  return (
    <div>
      <Fieldset legend="User Information" className="mb-3 ">
        <div className="flex flex-row gap-2 align-items-center justify-content-between">
          <div>
            <div className="flex flex-row gap-2 align-items-center">
              <div className="text-3xl">
                {user?.firstName} {user?.lastName}
              </div>
              {user && user.isActive ? (
                <Badge severity={"success"} value="Active" />
              ) : (
                <Badge severity={"danger"} value="Inactive" />
              )}
            </div>
            {user && <p>{user.emailAddress}</p>}
            {user && isStaff(user) && <p>{user.position?.name}</p>}
            {user && <p>{user.homeSite.name}</p>}
          </div>
          <div>
            {base64String && (
              <img
                src={`data:image/jpeg;base64,${base64String}`}
                alt={`${user?.firstName} ${user?.lastName} Image`}
                style={imageStyles}
              />
            )}
          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Assigned Inventory">
        {assets.length > 0 ? (
          <ul>
            {assets.map((asset) => (
              <li key={asset.id}>
                {asset.skuName} - {asset.serial}
              </li>
            ))}
          </ul>
        ) : (
          <p>N/A</p>
        )}
      </Fieldset>
    </div>
  );
};

export default UserProfileComponent;
