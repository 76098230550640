import CheckInPanel from "../components/CheckInPanel";
import { Fieldset } from "primereact/fieldset";

const CheckInPage: React.FC = () => {
    return (
        <div className="m-4 mb-2">
            <div className="grid">
                <div className="col-12 md:col-4">
                    <Fieldset legend="Check In Asset">
                        <CheckInPanel />
                    </Fieldset>
                </div>  
            </div>
        </div>
    );
};

export default CheckInPage;