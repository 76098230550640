import { Fieldset } from "primereact/fieldset";
import UserProfileComponent from "../components/UserProfileComponent";
import { useAuth } from "../contexts/AuthContext";
import { useEffect, useState } from "react";
import { useArmoryApi } from "../hooks/useArmoryApi";

//interface to accept userId
export interface UserProfileComponentProps {
    userId: number;
}

const ProfilePage: React.FC<UserProfileComponentProps> = ({userId}) => {
    return (
        <div className="m-4 mb-2">
            <div className="grid">
                <div className="col-8 mx-auto">
                    {userId && <UserProfileComponent userId={userId} />}
                </div>
            </div>            
        </div>
    );
}

export default ProfilePage