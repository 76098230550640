import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useArmory, useArmoryDispatch } from "../contexts/ArmoryContext";
import BulkAssignToSitePanel from "../components/BulkAssignToSitePanel";
import { TabView, TabPanel } from "primereact/tabview";
import BulkSetSiteOnlyPanel from "../components/BulkSetSiteOnlyPanel";
import BulkSetStatusPanel from "../components/BulkSetStatusPanel";
import { Button } from "primereact/button";
import { useArmoryApi } from "../hooks/useArmoryApi";

export interface AdminPageProps {
  userId: number;
}

const AdminPage: React.FC<AdminPageProps> = ({ userId }) => {
  const { userPerms, isCursorLoading } = useArmory();
  const dispatch = useArmoryDispatch();
  const { getHotspotReport, getLaptopReport } = useArmoryApi();

  const checkAdminStatus = async () => {
    if (!userPerms.isAdmin) {
      return <Navigate to="/" />;
    }
  };

  useEffect(() => {
    checkAdminStatus();
  }, []);

  const downloadLaptopReport = async () => {
    dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});
    await getLaptopReport();

    setTimeout(() => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);
  };

  const downloadHotspotReport = async () => {
    dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});
    await getHotspotReport();

    setTimeout(() => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);
  };

  return (
    <div className="mt-5 flex justify-content-center gap-3">
      <div className="flex flex-column">
        <Button
          label="Get Laptop Report"
          className="p-button-success m-2"
          onClick={downloadLaptopReport}
          disabled={isCursorLoading}
        />
        <Button
          label="Get Hotspot Report"
          className="p-button-success m-2"
          onClick={downloadHotspotReport}
          disabled={isCursorLoading}
        />
      </div>
      <div className="flex flex-column">
        <TabView scrollable>
          <TabPanel header="Assign To Site (Bulk)">
            <p className="mt-0 mb-5">
              Assign any number of Assets to the indicated site's inventory.
            </p>
            <BulkAssignToSitePanel />
          </TabPanel>
          <TabPanel header="Set Site Use Only (Bulk)">
            <p className="mt-0 mb-5">
              Designate any number of Assets as "Site Use Only".
            </p>
            <BulkSetSiteOnlyPanel />
          </TabPanel>
          <TabPanel header="Set Status (Bulk)">
            <p className="mt-0 mb-5">
              Update the Repair/Missing/Retired status of any number of Assets.
            </p>
            <BulkSetStatusPanel />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default AdminPage;
