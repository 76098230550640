import { useArmory, useArmoryDispatch } from "../contexts/ArmoryContext";
import SiteDropdown from "./SiteDropdown";

const ArmorySiteDropdown: React.FC = () => {
  const { site, isOnline } = useArmory();
  const dispatch = useArmoryDispatch();

  const handleChange = (site: any) => {
    dispatch({type: "SET_VALUE", payload: {key: "site", value: site}});
  };

  return <SiteDropdown handleChange={handleChange} disabled={!isOnline} selectedSiteName={site?.name} />;
};

export default ArmorySiteDropdown;
