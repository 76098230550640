import { useState } from "react";

import { useArmoryApi } from "../hooks/useArmoryApi";
import { useArmory, useArmoryDispatch } from "../contexts/ArmoryContext";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

const ReceiveAssetsPanel: React.FC = () => {
  const { isCursorLoading, site } = useArmory();
  const dispatch = useArmoryDispatch();
  const { receiveAssets, validateSerialNumbers } = useArmoryApi();
  const [serials, setSerials] = useState<string>("");

  const handleSerialChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSerials(e.target.value);
  };

  const handleSubmit = async () => {
    dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: true}});

    //convert serials to array
    const serialArr = serials.split("\n");

    //validate serials
    const serialResponse = await validateSerialNumbers(serialArr);
    if (serialResponse.statusCode === 200) {
      //serials valid, assign to site
      const response = await receiveAssets(serialArr, site?.id ?? 0);

      if (response.statusCode === 200) {
        const successMsg: string = `Receive assets succeeded.`;
        dispatch({type: "SHOW_TOAST", payload: {severity: "success", summary: "Success", details: successMsg, life: 3000}});
        dispatch({type: "ADD_LOG_ENTRY", payload: {message: `${successMsg}\r\nSerials: ${serialArr.join("\r\n")}`}});
        setSerials("");
      } else {
        const errorMsg: string = `Receive assets failed. ${response.error}`;
        dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", details: errorMsg, life: 3000}});
        dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
      }
    } else {
      const errorMsg: string = `One or more serial numbers failed to validate. ${serialResponse.error}`;
      dispatch({type: "SHOW_TOAST", payload: {severity: "error", summary: "Error", details: errorMsg, life: 3000}});
      dispatch({type: "ADD_LOG_ENTRY", payload: {message: errorMsg}});
    }

    setTimeout(() => {
      dispatch({type: "SET_VALUE", payload: {key: "isCursorLoading", value: false}});
    }, 0);
  };

  return (
    <div className="p-fluid">
      <InputTextarea
        value={serials}
        onChange={handleSerialChange}
        disabled={isCursorLoading}
        placeholder="Enter or scan serials"
        autoResize={true}
        className="p-mb-3 p-inputtext-sm"
      />
      <Button
        label="Submit"
        onClick={handleSubmit}
        disabled={isCursorLoading}
        className="p-button-rounded p-button-success p-mt-2"
      />
    </div>
  );
};

export default ReceiveAssetsPanel;
